import { render, staticRenderFns } from "./UserGroupRightTable.vue?vue&type=template&id=f48ff74e&scoped=true&"
import script from "./UserGroupRightTable.vue?vue&type=script&lang=js&"
export * from "./UserGroupRightTable.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupRightTable.vue?vue&type=style&index=0&id=f48ff74e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f48ff74e",
  null
  
)

export default component.exports