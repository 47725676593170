<template>
  <div class="contentList">
    <el-row :gutter="5" style="margin: 10px 0px">
      <el-col :span="9">
        <el-input
          style="margin-left: -4px"
          v-model.trim="input"
          @keyup.enter.native="search"
          :placeholder="$t('Delivery.distributionManagement.devicePlaceholder')"
          size="small"
          type="text"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="search"
          >
          </i>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="5" style="margin: 10px 0px">
      <el-col :span="24">
        <!-- 文档管理 -->
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin: 10px; color: #333333; font-size: 12px"
        >
          <el-breadcrumb-item @click.native="goHome" style="cursor: pointer">{{
            $t("Delivery.distributionManagement.docManagement")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="item in menuName"
            :key="item.id"
            style="cursor: pointer"
            @click.native="changeBread(item.id)"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="3">
      <el-table
        @select="selectRows"
        @select-all="selectAll"
        ref="contentList"
        row-key="id"
        stripe
        :data="documentListArray"
        class="sort_table"
        style="width: 99%"
        tooltip-effect="dark"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.name')"
          width="120"
        >
          <template v-slot="{ row }">
            <template v-if="row.docType == 'folder'">
              <img src="@/images/file/folder.png" alt="" class="nameImg" />
              <span
                style="color: #d38a08; cursor: pointer"
                @click="newDoc(row)"
                >{{ row.name }}</span
              >
            </template>
            <template v-else>
              <template>
                <img
                  v-if="row.docType == 'txt'"
                  src="@/images/file/txt.png"
                  alt=""
                  class="nameImg"
                />
                <img
                  v-if="row.docType == 'pdf'"
                  src="@/images/file/pdf.png"
                  alt=""
                  class="nameImg"
                />
                <img
                  v-if="
                    row.docType == 'jpeg' ||
                    row.docType == 'jpg' ||
                    row.docType == 'pen' ||
                    row.docType == 'png'
                  "
                  src="@/images/file/jpg.png"
                  alt=""
                  class="nameImg"
                />
                <img
                  v-if="row.docType == 'ppt' || row.docType == 'pptx'"
                  src="@/images/file/ppt.png"
                  alt=""
                  class="nameImg"
                />
                <img
                  v-if="row.docType == 'docx' || row.docType == 'doc'"
                  src="@/images/file/docx.png"
                  alt=""
                  class="nameImg"
                />
                <img
                  v-if="row.docType == 'xls' || row.docType == 'xlsx'"
                  src="@/images/file/xls.png"
                  alt=""
                  class="nameImg"
                />
                <span>{{ row.name }}</span>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="groupFullName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.department')"
          width="120"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName" style="vertical-align: middle">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
            <span v-else style="vertical-align: middle">
              {{ row.groupFullName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.creator')"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.ModificationTime')"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <p
      style="
        color: #000;
        line-height: 22px;
        text-align: left;
        margin-left: 10px;
        margin-top: 5px;
      "
    >
      {{ $t("public.total") }}
      <span style="font-weight: bold">{{ total }}</span>
      {{ $t("UserManage.adminPage.Records") }}
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      groupFullNameShowByIndex: "",
      documentListArray: [],
      total: "", //分配数据总数
      input: "", //查询输入框
      menuName: [],
      searchData: {
        currentPage: 1,
        pageSize: 999,
        parentId: "0",
        name: "",
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        valid: "",
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  methods: {
    async getList() {
      var params = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: this.searchData.parentId,
        name: "",
        // orderColume: this.searchData.orderColume,
        // orderRule: this.searchData.orderRule,
        orderColume: 'd.isFolder ASC, d.lastUpdateTime',//上海联通迁移
        orderRule: 'DESC',//上海联通迁移
        valid: this.searchData.valid,
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        params,
        true
      );
      res.data.content.forEach((item) => {
        this.$set(item, "configType", "文档内容");
      });
      this.documentListArray = res.data.content;
      this.total = res.data.total;
      if (this.$store.state.dialogVisibleTitle == "4") {
        var listPolicy = [];
        this.$store.state.SelectAssignData.map(function (track) {
          if (track.configType == "4" || track.configType == "文档内容") {
            listPolicy.push(track);
          }
        });
        if (listPolicy.length > 0) {
          // this.rowSelectFlag = true;
          // console.log(arr,arr.map((val) => val.id),"葛发财111");
          res.data.content.forEach((row) => {
            console.log(row, "row");
            const matchedIndex = listPolicy.findIndex(
              (item) => item.id == row.id
            );
            console.log(matchedIndex, "matchedIndex");
            this.$refs.contentList?.toggleRowSelection(row, matchedIndex != -1);
          });
        }
      }
    },

    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //查询
    async search() {
      var params = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: this.searchData.parentId,
        name: this.input,
        // orderColume: this.searchData.orderColume,
        // orderRule: this.searchData.orderRule,
        orderColume: 'd.isFolder ASC, d.lastUpdateTime',//上海联通迁移
        orderRule: 'DESC',//上海联通迁移
        valid: this.searchData.valid,
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        params,
        true
      );
      res.data.content.forEach((item) => {
        this.$set(item, "configType", "文档内容");
      });
      this.documentListArray = res.data.content;
      this.total = res.data.total;
    },
    //全选
    selectAll(selection) {
      // // console.log(selection, '全选')
      this.$emit("selectRows", selection);
    },
    selectRows(selection, row) {
      // debugger;
      // console.log(selection, 'selection')
      // console.log(row, 'row')

      this.$emit("selectRows", selection);
    },
    // 跳转面包屑文档管理
    async goHome() {
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: "0",
        name: "",
        // orderColume: this.searchData.orderColume,
        // orderRule: this.searchData.orderRule,
        orderColume: 'd.isFolder ASC, d.lastUpdateTime',//上海联通迁移
        orderRule: 'DESC',//上海联通迁移
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.documentListArray = res.data.content;
      this.total = res.data.total;
      this.menuName = [];
    },
    // 面包屑跳转
    async changeBread(id) {
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: String(id),
        name: "",
        valid: "",
        // orderColume: this.searchData.orderColume,
        // orderRule: this.searchData.orderRule,
        orderColume: 'd.isFolder ASC, d.lastUpdateTime',//上海联通迁移
        orderRule: 'DESC',//上海联通迁移
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.documentListArray = res.data.content;
      this.total = res.data.total;
      let menuArr = this.menuName;
      this.menuName = menuArr.splice(-1, 1);
      this.menuName = menuArr;
    },
    // 文件夹列表
    async newDoc(val) {
      let data = [];
      let obj = { name: val.name, id: val.id };
      data.push(obj);
      this.menuName = this.menuName.concat(data);
      this.parentId = val.id;
      this.searchData.parentId = String(val.id);
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: this.searchData.parentId,
        name: this.input,
        // orderColume: this.searchData.orderColume,
        // orderRule: this.searchData.orderRule,
        orderColume: 'd.isFolder ASC, d.lastUpdateTime',//上海联通迁移
        orderRule: 'DESC',//上海联通迁移
        valid: this.searchData.valid,
      };
      // console.log(parma)
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.documentListArray = res.data.content;
      this.total = res.data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.nameImg {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 27% !important;
}
// 面包屑hover效果
::v-deep .el-breadcrumb__inner {
  color: #d38a08;
  cursor: pointer;
}
</style>
