<template>
  <div class="disManagement">
    <navi-gation />
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleLoad"
      class="tabs"
      :before-leave="beforeLeave"
      style="margin-right: 10px"
    >
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AllocationContent')"
        name="1"
      >
        <contentTab
          :key="timer1"
          @tableContent="tableContent1"
          @defaultCheck="defaultChecked"
          ref="contentData"
        ></contentTab>
      </el-tab-pane>
      <!-- 按部门分配 -->
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AllocateByDepartment')"
        name="2"
      >
        <groupTab
          :key="timer2"
          ref="groupData"
          @tableContent="tableContent1"
        ></groupTab>
      </el-tab-pane>
      <!-- 按用户组分配 -->
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AssignByUserGroup')"
        name="3"
      >
        <userGroupTab
          :key="timer3"
          ref="userGroupData"
          @tableContent="tableContent1"
        ></userGroupTab>
      </el-tab-pane>
      <!-- 按用户分配 -->
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AssignByUser')"
        name="4"
      >
        <userTab
          :key="timer4"
          ref="userData"
          @tableContent="tableContent1"
        ></userTab>
      </el-tab-pane>
      <!-- 按设备组分配 -->
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AssignByDeviceGroup')"
        name="5"
      >
        <deviceGroupTab
          :key="timer5"
          ref="deviceGroupData"
          @tableContent="tableContent1"
        ></deviceGroupTab>
      </el-tab-pane>
      <!-- 按设备分配 -->
      <el-tab-pane
        :label="$t('Delivery.distributionManagement.AssignByDevice')"
        name="6"
      >
        <deviceTab
          :key="timer6"
          ref="deviceData"
          @tableContent="tableContent1"
        ></deviceTab>
      </el-tab-pane>
    </el-tabs>
    <el-button
      type="primary"
      size="small"
      style="margin-left: 30px; margin-top: 20px"
      @click="save"
      >{{ $t("public.Save") }}</el-button
    >
    <!-- 提示信息 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose1"
      @determine="determine1"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index"; //提示信息
import userTab from "./components/disManagement/userDistributor";
import groupTab from "./components/disManagement/groupDistributor";
import deviceTab from "./components/disManagement/deviceDistributor";
import contentTab from "./components/disManagement/distributionContent"; //分配内容
import userGroupTab from "./components/disManagement/userGroupDistributor.vue";
import deviceGroupTab from "./components/disManagement/deviceGroupDistributor.vue";
export default {
  components: {
    userTab,
    groupTab,
    deviceTab,
    naviGation,
    pagination,
    dialogInfo,
    contentTab,
    userGroupTab,
    deviceGroupTab,
  },
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      idArr: [], //保存数据id
      saveFalg: false, //判断分配内容数据
      titleInfo: "", // 提示框的提示语
      activeName: "1",
      saveContent: {}, //保存id对象
      visibaelFlag: false, //提示消息默认--关闭
      groupDefaultChecked: [], //部门默认勾选的id
      userDefaultChecked: [], //用户默认勾选的id
      deviceDefaultChecked: [], //设备默认勾选的id
      userGroupDefaultChecked: [], //用户组默认勾选的id---------V6.1.3
      deviceGroupDefaultChecked: [], //设备组默认勾选的id---------V6.1.3
    };
  },
  watch: {
    "$store.state.SelectAssignData": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New SelectAssignData-value: ", newVal);
      console.log("Old SelectAssignData-value: ", oldVal);
      // this.showCheck(this.tableList, newVal);
      if (newVal.length === 0) {
        this.saveFalg = false;
      }
      if (newVal.length > 0) {
        this.saveFalg = true;
      }
    },
  },
  mounted() {},
  //离开路由页面后执行函数
  destroyed() {
    //清除存在本地的数据
    this.$store.state.SelectAssignData = [];
    this.$store.state.deledArr = [];
    this.$store.state.deviceList = [];
    this.$store.state.userList = [];
    this.$store.state.groupList = [];
    this.$store.state.addGroupList = [];
    this.$store.state.addUserList = [];

    this.$store.state.userGropList = [];
    this.$store.state.addUserGropList = [];
    this.$store.state.addDevGropList = [];
    this.$store.state.deviceGroupList = [];

    sessionStorage.removeItem("complianceList");
    this.$store.commit("delete", null);
  },
  methods: {
    //弹框回调事件
    handleClose1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    // 删除两个数组对象中相同的对象函数封装
    resArr(arr1, arr2) {
      return arr1.filter((v) => arr2.every((val) => val.name != v.name));
    },

    handleLoad(data) {
      console.log(data.index, "data.index");
      if (data.index == 1) {
        this.$store.commit("groupTab", data.index);
      }
      if (data.index == 2) {
        this.$store.commit("userGroupTab", data.index);
      }
      if (data.index == 3) {
        this.$store.commit("userTab", data.index);
      }
      if (data.index == 4) {
        this.$store.commit("deviceGroupTab", data.index);
      }
      if (data.index == 5) {
        this.$store.commit("deviceTab", data.index);
      }
      let name = data.name;

      if (name == 1) {
        //调用
        if (this.$store.state.deledArr.length > 0) {
          var newArr = [...this.$store.state.SelectAssignData];
          this.$store.state.SelectAssignData = this.resArr(
            newArr,
            this.$store.state.deledArr
          );
        } else {
          this.timer1 = new Date().getTime();
        }
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      } else if (name == 4) {
        this.timer4 = new Date().getTime();
      } else if (name == 5) {
        this.timer5 = new Date().getTime();
      } else if (name == 6) {
        this.timer6 = new Date().getTime();
      }
    },
    //分配保存的数据
    tableContent1(cont) {
      this.saveContent = cont;
    },
    //回显勾选的数据
    defaultChecked(data) {
      this.groupDefaultChecked = data.groupList?.content;
      this.userDefaultChecked = data.userList;
      this.deviceDefaultChecked = data.deviceList;
      this.userGroupDefaultChecked = data.userGroupList; //用户组默认勾选的id---------V6.1.3
      this.deviceGroupDefaultChecked = data.deviceGroupList; //设备组默认勾选的id---------V6.1.3
    },
    //切换标签之前的钩子，若返回 false 或者返回 Promise 且被 reject，则阻止切换。
    beforeLeave(activeName, oldActiveName) {
      if (this.saveFalg === false) {
        this.$message({
          message: this.$t(
            "Delivery.distributionManagement.SelectAllocateContent"
          ),
          type: "error",
          offset: 100,
        });
        return false;
      }
    },
    //保存
    async save() {
      console.log(
        "分配内容数据长度：",
        this.$store.state.SelectAssignData.length
      );
      if (this.saveFalg === false) {
        this.$message({
          message: this.$t(
            "Delivery.distributionManagement.SelectAllocateContent"
          ),
          type: "error",
          offset: 100,
        });
        return false;
      } else if (this.saveFalg === true) {
        this.activeName = "1";
        var toObject = {
          device: [],
          group: [],
          user: [],
          userGroup: [], //用户组--------V6.1.3
          deviceGroup: [], //设备组--------V6.1.3
        };

        // --------------------------
        //部门表格分配的数据
        const toGroup = this.$store.state.groupRgihtArray.map(
          (item) => item.id
        );
        toObject.group = [...new Set(toGroup)];
        // --------------------------
        //用户组表格分配的数据
        const toUserGroup = this.$store.state.userGropRgihtArray.map(
          (item) => item.id
        );
        toObject.userGroup = [...new Set(toUserGroup)];
        // --------------------------
        //用户表格分配的数据
        const toUser = this.$store.state.userRgihtArray.map((item) => item.id);
        toObject.user = [...new Set(toUser)];
        // --------------------------
        //设备组表格分配的数据
        const toDeviceGroup = this.$store.state.deviceGropRgihtArray.map(
          (item) => item.id
        );
        toObject.deviceGroup = [...new Set(toDeviceGroup)];
        // --------------------------
        //设备表格分配的数据
        console.log("设备右侧数据:", this.$store.state.deviceRgihtArray);
        const toDevice = this.$store.state.deviceRgihtArray.map(
          (item) => item.id
        );
        toObject.device = [...new Set(toDevice)];
        console.log(toObject.device, toObject.user, toObject.group, "347行");
        console.log(this.saveContent.apps, "apps");
        console.log(this.saveContent.compliancePolicies, "compliancePolicies");
        console.log(this.saveContent.devicePolicies, "devicePolicies");
        console.log(this.saveContent.documents, "documents");
        console.log(this.saveContent.notices, "notices");
        if (
          // toObject.device.length == 0 &&
          // toObject.user.length == 0 &&
          // toObject.group.length == 0
          this.saveContent.apps.length == 0 &&
          this.saveContent.compliancePolicies.length == 0 &&
          this.saveContent.devicePolicies.length == 0 &&
          this.saveContent.documents.length == 0 &&
          this.saveContent.notices.length == 0
          // && this.saveContent.wallpapers.length == 0
        ) {
          // this.$message.closeAll()
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateObject"
            ),
            type: "error",
            offset: 100,
          });
          return false;
        } else {
          var params = {
            apps: this.saveContent.apps,
            compliancePolicies: this.saveContent.compliancePolicies,
            devicePolicies: this.saveContent.devicePolicies,
            documents: this.saveContent.documents,
            notices: this.saveContent.notices,
            // wallpapers: this.saveContent.wallpapers,
            wallpapers: [],
            to: {
              device: toObject.device,
              group: toObject.group,
              user: toObject.user,
              userGroup: toObject.userGroup, //用户组
              deviceGroup: toObject.deviceGroup, //设备组
            },
          };
          console.log(params, "保存参数");
          const res = await this.$axios
            .post("/httpServe/delivery/alloction", params, true)
            .then(() => {
              this.$message({
                message: this.$t(
                  "Delivery.distributionManagement.AssignedSuccessfully"
                ),
                type: "success",
                offset: 100,
              });
              this.$refs.contentData.value = "设备策略";
              this.$refs.contentData.clearTable(); //调用子组件清除表格数据
              this.$store.state.SelectAssignData = []; //清除存在本地的数据
              this.$store.state.deledArr = [];
              this.$store.state.deviceList = [];
              this.$store.state.userList = [];
              this.$store.state.deviceGroupList = []; //设备组---------V6.1.3
              this.$store.state.userGropList = []; //用户组---------V6.1.3
              this.$store.state.groupList = [];
              this.$store.state.addGroupList = [];
              this.$store.state.addUserList = [];
              this.$store.state.addDevGropList = [];
              this.saveContent = {};
              sessionStorage.removeItem("complianceList");
              this.$store.commit("delete", null);
              return false;
            })
            .catch(() => {
              return false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0px;
  margin: 0px !important;
  position: relative;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
</style>
