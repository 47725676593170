var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"distriContent"},[_c('el-row',{staticStyle:{"padding":"5px 0 0 20px"},attrs:{"gutter":3}},[_c('el-col',{staticStyle:{"font-size":"12px","margin-top":"5px"},attrs:{"span":1}},[_vm._v(_vm._s(_vm.$t("Delivery.distributionManagement.AllocationContent") + "："))]),_c('el-col',{attrs:{"span":2}},[_c('el-select',{staticStyle:{"font-size":"12px"},attrs:{"size":"mini"},on:{"change":_vm.changeContent},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(`Delivery.distributionManagement.content.${item.label}`),"value":item.value}})}),1)],1),_c('el-col',{attrs:{"span":3}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.choice}},[_vm._v(_vm._s(_vm.$t("Delivery.distributionManagement.select")))])],1)],1),_c('el-row',{staticStyle:{"margin-top":"10px","padding":"0px 20px"},attrs:{"gutter":3}},[_c('div',{staticClass:"bigBox",staticStyle:{"height":"455px","overflow":"auto"}},[_c('el-table',{ref:"contentTable",staticClass:"sort_table",staticStyle:{"width":"100%"},attrs:{"size":"small","stripe":"","fit":"","data":_vm.$store.state.SelectAssignData,"tooltip-effect":"dark","row-style":_vm.iRowStyle,"cell-style":_vm.iCellStyle,"header-row-style":_vm.iHeaderRowStyle,"header-cell-style":{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }}},[_c('el-table-column',{attrs:{"type":"index","label":_vm.$t('Delivery.distributionManagement.SerialNumber')}}),_c('el-table-column',{attrs:{"prop":"configType","label":_vm.$t('Delivery.distributionManagement.classification'),"width":"180","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.configType == '1' ||
                scope.row.configType == '设备策略'
              ),expression:"\n                scope.row.configType == '1' ||\n                scope.row.configType == '设备策略'\n              "}]},[_vm._v(" "+_vm._s(_vm.$t("Delivery.distributionManagement.devicePolicy"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.configType == '2' ||
                scope.row.configType == '合规策略'
              ),expression:"\n                scope.row.configType == '2' ||\n                scope.row.configType == '合规策略'\n              "}]},[_vm._v(" "+_vm._s(_vm.$t("Delivery.distributionManagement.CompStrategy"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.configType == '3' ||
                scope.row.configType == '0' ||
                scope.row.configType == '应用程序'
              ),expression:"\n                scope.row.configType == '3' ||\n                scope.row.configType == '0' ||\n                scope.row.configType == '应用程序'\n              "}]},[_vm._v(" "+_vm._s(_vm.$t("Delivery.distributionManagement.MobileApp"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.configType == '4' ||
                scope.row.configType == '文档内容'
              ),expression:"\n                scope.row.configType == '4' ||\n                scope.row.configType == '文档内容'\n              "}]},[_vm._v(" "+_vm._s(_vm.$t("Delivery.distributionManagement.docContent"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.configType == '5' ||
                scope.row.configType == '通知内容'
              ),expression:"\n                scope.row.configType == '5' ||\n                scope.row.configType == '通知内容'\n              "}]},[_vm._v(" "+_vm._s(_vm.$t("Delivery.distributionManagement.NotContent"))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('Delivery.distributionManagement.name'),"width":"180","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"versionName","label":_vm.$t('Delivery.distributionManagement.version'),"width":"180","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.configType == '3' ||
                scope.row.configType == '0' ||
                scope.row.configType == '应用程序'
              )?_c('span',[_vm._v(" "+_vm._s(scope.row.versionName)+" ")]):_c('span',[_vm._v(" / ")])]}}])}),_c('el-table-column',{attrs:{"prop":"scopeStr","label":_vm.$t('Delivery.distributionManagement.operatingSystem'),"width":"180","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.scopeStr == 20 || row.scopeStr == 'Android')?_c('span',[_vm._v(" Android ")]):(row.scopeStr == 10 || row.scopeStr == 'iOS')?_c('span',[_vm._v(" iOS ")]):(row.osTypeId == 30 || row.scopeStr == 'Windows')?_c('span',[_vm._v(" Windows ")]):(row.osTypeId == 40 || row.scopeStr == 'Linux')?_c('span',[_vm._v(" Linux ")]):_c('span',[_vm._v(" / ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('public.Controls')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticStyle:{"margin-bottom":"-4px !important","cursor":"pointer"},attrs:{"src":require("@/images/icon_del.png"),"alt":"","title":_vm.$t('public.Delete')},on:{"click":function($event){return _vm.del(scope)}}})]}}])})],1)],1)]),(_vm.outerVisible)?_c('Eldialog',{staticClass:"dialog",attrs:{"title":_vm.title,"flagbtn":_vm.flagbtn,"btnTitle":_vm.$t('Delivery.distributionManagement.join'),"determineBtn":_vm.determineBtn,"cancelBtn":_vm.cancelBtn,"dialogVisible":_vm.outerVisible,"num":'400px'},on:{"handleClose":_vm.handleClose,"determine":_vm.determine}},[_c('div',{staticClass:"box-corn",staticStyle:{"padding":"0px 2px"}},[(
          _vm.title == _vm.$t('Delivery.distributionManagement.SelectDevicePolicy')
        )?_c('content-list',{ref:"devComponent",on:{"selectRows":_vm.seleContent}}):_vm._e(),(
          _vm.title ==
          _vm.$t('Delivery.distributionManagement.SelectComplianceStrategy')
        )?_c('compliance-list',{ref:"heComponent",on:{"selectRows":_vm.seleCompList}}):_vm._e(),(_vm.title == _vm.$t('Delivery.distributionManagement.ChooseMobileApp'))?_c('mobile-list',{ref:"moComponent",on:{"selectRows":_vm.seleMobileList}}):_vm._e(),(_vm.title == _vm.$t('Delivery.distributionManagement.SelectDocContent'))?_c('documentList',{ref:"doComponent",on:{"selectRows":_vm.seleDocumentList}}):_vm._e(),(_vm.title == _vm.$t('Delivery.distributionManagement.SelectNotContent'))?_c('notice-list',{ref:"noComponent",on:{"selectRows":_vm.seleNoticeList}}):_vm._e()],1),(_vm.flagbtn)?_c('div',{staticClass:"diailog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.determineBtn)?_c('el-button',{staticClass:"btn-determine",attrs:{"size":"small","type":"primary"},on:{"click":_vm.determine}},[_vm._v(_vm._s(_vm.$t("Delivery.distributionManagement.join")))]):_vm._e(),(_vm.cancelBtn)?_c('el-button',{staticClass:"btn-cancel",attrs:{"size":"small","type":"primary"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("Delivery.distributionManagement.cancel")))]):_vm._e()],1):_vm._e()]):_vm._e(),_c('dialog-info',{attrs:{"dialogInfo":_vm.visibaelFlag,"titleInfo":_vm.titleInfo,"width":'240px'},on:{"handleClose":_vm.handleClose1,"determine":_vm.determine1}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }