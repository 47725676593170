<template>
  <!-- 分配内容 -->
  <div class="distriContent">
    <el-row :gutter="3" style="padding: 5px 0 0 20px">
      <el-col :span="1" style="font-size: 12px; margin-top: 5px">{{
        $t("Delivery.distributionManagement.AllocationContent") + "："
      }}</el-col>
      <el-col :span="2">
        <el-select
          size="mini"
          style="font-size: 12px"
          v-model="value"
          @change="changeContent"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="$t(`Delivery.distributionManagement.content.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" @click="choice">{{
          $t("Delivery.distributionManagement.select")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="3" style="margin-top: 10px; padding: 0px 20px">
      <div style="height: 455px; overflow: auto" class="bigBox">
        <el-table
          size="small"
          stripe
          fit
          ref="contentTable"
          :data="$store.state.SelectAssignData"
          tooltip-effect="dark"
          class="sort_table"
          style="width: 100%"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
        >
          <el-table-column
            type="index"
            :label="$t('Delivery.distributionManagement.SerialNumber')"
          >
          </el-table-column>
          <el-table-column
            prop="configType"
            :label="$t('Delivery.distributionManagement.classification')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                v-show="
                  scope.row.configType == '1' ||
                  scope.row.configType == '设备策略'
                "
              >
                {{ $t("Delivery.distributionManagement.devicePolicy") }}
              </span>
              <span
                v-show="
                  scope.row.configType == '2' ||
                  scope.row.configType == '合规策略'
                "
              >
                {{ $t("Delivery.distributionManagement.CompStrategy") }}
              </span>
              <span
                v-show="
                  scope.row.configType == '3' ||
                  scope.row.configType == '0' ||
                  scope.row.configType == '应用程序'
                "
              >
                {{ $t("Delivery.distributionManagement.MobileApp") }}
              </span>
              <span
                v-show="
                  scope.row.configType == '4' ||
                  scope.row.configType == '文档内容'
                "
              >
                {{ $t("Delivery.distributionManagement.docContent") }}
              </span>
              <span
                v-show="
                  scope.row.configType == '5' ||
                  scope.row.configType == '通知内容'
                "
              >
                {{ $t("Delivery.distributionManagement.NotContent") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('Delivery.distributionManagement.name')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="versionName"
            :label="$t('Delivery.distributionManagement.version')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.configType == '3' ||
                  scope.row.configType == '0' ||
                  scope.row.configType == '应用程序'
                "
              >
                {{ scope.row.versionName }}
              </span>
              <span v-else> / </span>
            </template>
          </el-table-column>
          <!-- <el-table-column :show-overflow-tooltip="true" prop="cerSign" label="签名证书指纹值" width="130">
          </el-table-column> -->
          <el-table-column
            prop="scopeStr"
            :label="$t('Delivery.distributionManagement.operatingSystem')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.scopeStr == 20 || row.scopeStr == 'Android'">
                Android
              </span>
              <span v-else-if="row.scopeStr == 10 || row.scopeStr == 'iOS'">
                iOS
              </span>
              <span v-else-if="row.osTypeId == 30 || row.scopeStr == 'Windows'">
                Windows
              </span>
              <span v-else-if="row.osTypeId == 40 || row.scopeStr == 'Linux'">
                Linux
              </span>
              <span v-else> / </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <img
                src="@/images/icon_del.png"
                alt=""
                @click="del(scope)"
                style="margin-bottom: -4px !important; cursor: pointer"
                :title="$t('public.Delete')"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      class="dialog"
      @handleClose="handleClose"
      :title="title"
      :flagbtn="flagbtn"
      :btnTitle="$t('Delivery.distributionManagement.join')"
      :determineBtn="determineBtn"
      :cancelBtn="cancelBtn"
      @determine="determine"
      :dialogVisible="outerVisible"
      :num="'400px'"
      v-if="outerVisible"
    >
      <div class="box-corn" style="padding: 0px 2px">
        <content-list
          v-if="
            title == $t('Delivery.distributionManagement.SelectDevicePolicy')
          "
          @selectRows="seleContent"
          ref="devComponent"
        ></content-list>

        <compliance-list
          v-if="
            title ==
            $t('Delivery.distributionManagement.SelectComplianceStrategy')
          "
          @selectRows="seleCompList"
          ref="heComponent"
        ></compliance-list>

        <mobile-list
          ref="moComponent"
          v-if="title == $t('Delivery.distributionManagement.ChooseMobileApp')"
          @selectRows="seleMobileList"
        ></mobile-list>

        <documentList
          v-if="title == $t('Delivery.distributionManagement.SelectDocContent')"
          @selectRows="seleDocumentList"
          ref="doComponent"
        ></documentList>

        <notice-list
          v-if="title == $t('Delivery.distributionManagement.SelectNotContent')"
          @selectRows="seleNoticeList"
          ref="noComponent"
        ></notice-list>
      </div>
      <div v-if="flagbtn" slot="footer" class="diailog-footer">
        <el-button
          v-if="determineBtn"
          size="small"
          type="primary"
          class="btn-determine"
          @click="determine"
          >{{ $t("Delivery.distributionManagement.join") }}</el-button
        >
        <el-button
          v-if="cancelBtn"
          size="small"
          type="primary"
          class="btn-cancel"
          @click="cancel"
          >{{ $t("Delivery.distributionManagement.cancel") }}</el-button
        >
      </div>
    </Eldialog>
    <!-- 提示信息 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose1"
      @determine="determine1"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import noticeList from "./noticeList"; //通知内容
import mobileList from "./mobileList"; //应用程序
import contentList from "./contentList"; //设备策略
import documentList from "./documentList"; //文档内容
import complianceList from "./complianceList"; //合规策略
import Eldialog from "@/components/elDialog/index.vue"; //弹框
import dialogInfo from "@/components/promptMessage/index"; //提示信息
import { log } from "console";

export default {
  components: {
    Eldialog,
    dialogInfo,
    mobileList,
    noticeList,
    contentList,
    documentList,
    complianceList,
  },
  props: {
    dataSaveFlag: Boolean,
  },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      visFlag: "", //弹框什么时候显示
      options: [
        {
          value: "1",
          label: "设备策略",
        },
        {
          value: "2",
          label: "合规策略",
        },
        {
          value: "3",
          label: "应用程序", //应用程序 修改 为 “应用程序” v6.2.0版本
        },
        {
          value: "4",
          label: "文档内容",
        },
        {
          value: "5",
          label: "通知内容",
        },
      ],
      value: "1",
      detailsArray: [], //选择内容数据
      compListArray: [], //选择合规策略数据
      mobileListArray: [], //选择应用程序数据
      documentListArray: [], //选择文档内容数据
      noticeListArray: [], //选择通知内容数据
      outerVisible: false, // 选择内容数据弹框
      title: this.$t("Delivery.distributionManagement.ChooseMobileApp"), //选择内容标题
      tableContent: [], //分配的数据
      flagbtn: true,
      cancelBtn: true,
      determineBtn: true,
      // btnTitle: ,
      delSeledevFlag: false, //全部取消回显设备策略默认不取消
      delSeleComFlag: false, //全部取消回显合规策略默认不取消
      delSeleMovFlag: false, //全部取消回显应用程序默认不取消
      delSeleDocFlag: false, //全部取消回显文档内容默认不取消
      delSeleNotFlag: false, //全部取消回显通知内容默认不取消
      seleDevArr: [], //被勾选的设备策略
      seleComArr: [], //被勾选的合规策略
      seleMoveArr: [], //被勾选的应用程序
      seleDocumentArr: [], //被勾选的文档内容
      seleNoticeArr: [], //被勾选的通知内容
      emptyObj: {}, //定义一个空对象在应用程序中使用
      deviceBool: 0, //默认无重复勾选设备策略数据操作系统
      compliceBool: 0, //默认无重复勾选合规策略数据操作系统
      // moveBool: false, //默认无重复勾选应用程序数据操作系统
      moveCount: 0, //默认无重复勾选应用程序数据操作系统
      noticeBool: false, //默认无重复勾选应用程序数据操作系统
      documentBool: false, //默认无重复勾选应用程序数据操作系统
      content: {
        apps: [],
        compliancePolicies: [],
        devicePolicies: [],
        documents: [],
        notices: [],
      },
      to: {
        device: [],
        group: [],
        user: [],
      },
      sessionArr: [],
    };
  },
  watch: {
    "$store.state.SelectAssignData": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("监听和处理New SelectAssignData-value: ", newVal);
      console.log("监听和处理Old SelectAssignData-value: ", oldVal);
      // this.showCheck(this.tableList, newVal);
      if (newVal.length === 0) {
        this.seleDevArr = [];
        this.seleComArr = [];
        this.seleMoveArr = [];
        this.seleDocumentArr = [];
        this.seleNoticeArr = [];
        this.seleDesktopArr = [];
      }
      if (newVal.length > 0) {
        let dev = [];
        let Doc = [];
        let Mov = [];
        let Com = [];
        let Not = [];
        newVal.map(function (track) {
          if (track.configType == "4" || track.configType == "文档内容") {
            Doc.push(track);
            // return track;
          }
        });
        this.seleDocumentArr = dev;

        newVal.map(function (track) {
          if (track.configType == "5" || track.configType == "通知内容") {
            Not.push(track);
            // return track;
          }
        });
        this.seleNoticeArr = Not;

        newVal.map(function (track) {
          if (track.configType == "1" || track.configType == "设备策略") {
            dev.push(track);
            // return track;
          }
        });
        this.seleDevArr = dev;
        console.log(this.seleDevArr, "watch");

        newVal.map(function (track) {
          if (track.configType == "2" || track.configType == "合规策略") {
            Com.push(track);
            // return track;
          }
        });
        this.seleComArr = Com;

        newVal.map(function (track) {
          if (track.configType == "3" || track.configType == "应用程序") {
            Mov.push(track);
            // return track;
          }
        });
        this.seleMoveArr = Mov;
      }
    },
  },
  mounted() {
    this.tableContentList = this.$store.state.SelectAssignData;
    this.jumpFn();
  },
  methods: {
    fnScopeStr(tempArr) {
      let newArr = [];
      for (let i = 0; i < tempArr.length; i++) {
        if (newArr.indexOf(tempArr[i].scopeStr) == -1) {
          newArr.push(tempArr[i].scopeStr);
        } else {
          tempArr.splice(i, 1);
        }
      }
      return tempArr;
    },

    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    jumpFn() {
      if (JSON.parse(sessionStorage.getItem("complianceList")) == null) {
        this.visFlag = "";
        return false;
      } else {
        this.sessionArr = JSON.parse(sessionStorage.getItem("complianceList"));
        this.visFlag = this.sessionArr[0].value;

        this.options.forEach((item) => {
          if (this.sessionArr[0].value == item.value) {
            this.value = item.value;
            if (this.value == "3") {
              this.moveData(this.sessionArr);
              this.content.apps = this.sessionArr[0]?.idList;
              this.title = this.$t(
                "Delivery.distributionManagement.ChooseMobileApp"
              );
              this.addMoveData(); //加入接口
            } else if (this.value == "1") {
              this.deviceData(this.sessionArr);
              this.content.devicePolicies = this.sessionArr[0]?.idList;
              this.title = this.$t(
                "Delivery.distributionManagement.SelectDevicePolicy"
              );
              this.addMoveData(); //加入接口
            } else if (this.value == "2") {
              this.comData(this.sessionArr);
              this.content.compliancePolicies = this.sessionArr[0]?.idList;
              this.title = this.$t(
                "Delivery.distributionManagement.SelectComplianceStrategy"
              );
              this.addMoveData(); //加入接口
            } else if (this.value == "4") {
              this.docData(this.sessionArr);
              this.content.documents = this.sessionArr[0]?.idList;
              this.title = this.$t(
                "Delivery.distributionManagement.SelectDocContent"
              );
              this.addMoveData(); //加入接口
            } else if (this.value == "5") {
              this.notData(this.sessionArr);
              this.content.notices = this.sessionArr[0]?.idList;
              this.title = this.$t(
                "Delivery.distributionManagement.SelectNotContent"
              );
              this.addMoveData(); //加入接口
            }
          }
        });
      }
    },
    //设备策略数据回显
    async deviceData(v) {
      var params = {
        currentPage: 1,
        pageSize: 999,
        orderColume: "client.lastUpdateTime",
        orderRule: "DESC",
        configType: 1,
      };

      const res = await this.$axios.post(
        "/httpServe/policy/getDataInfo",
        params,
        true
      );

      let arr1 = [];
      const fn3 = (tempArr) => {
        let result = [];
        let obj = {};
        for (let i = 0; i < tempArr.length; i++) {
          if (!obj[tempArr[i].scopeStr]) {
            result.push(tempArr[i]);
            obj[tempArr[i].scopeStr] = true;
          }
        }
        return result;
      };
      for (var i = 0; i < res.data.content.length; i++) {
        for (var j = 0; j < v[0]?.idList.length; j++) {
          if (res.data.content[i].id == v[0]?.idList[j]) {
            res.data.content[i].configType = this.value;
            arr1.unshift(res.data.content[i]);
            this.seleDevArr = fn3(arr1);
            const new2 = [];
            fn3(arr1).forEach((item) => {
              new2.unshift(item.id);
            });
            this.content.devicePolicies = [...new2];
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
          }
        }
      }
    },
    //合规策略数据回显
    async comData(v) {
      var params = {
        currentPage: 1,
        pageSize: 999,
        orderColume: "client.lastUpdateTime",
        orderRule: "DESC",
        configType: 2,
      };

      const res = await this.$axios.post(
        "/httpServe/policy/getDataInfo",
        params,
        true
      );

      let arr1 = [];
      const fn3 = (tempArr) => {
        let result = [];
        let obj = {};
        for (let i = 0; i < tempArr.length; i++) {
          if (!obj[tempArr[i].scopeStr]) {
            result.push(tempArr[i]);
            obj[tempArr[i].scopeStr] = true;
          }
        }
        return result;
      };
      for (var i = 0; i < res.data.content.length; i++) {
        for (var j = 0; j < v[0]?.idList.length; j++) {
          if (res.data.content[i].id == v[0]?.idList[j]) {
            res.data.content[i].configType = this.value;
            arr1.unshift(res.data.content[i]);
            this.seleComArr = fn3(arr1);
            const new2 = [];
            fn3(arr1).forEach((item) => {
              new2.unshift(item.id);
            });
            // console.log(this.seleComArr, 'this.seleComArr')
            this.content.compliancePolicies = [...new2];
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
          }
        }
      }
    },
    //应用程序数据回显
    async moveData(v) {
      const ids = [];
      v[0].idList.forEach((i) => {
        i = i + "";
        ids.push(i);
      });

      var params = { ids: ids };
      const res = await this.$axios.post(
        "/httpServe/entApp/selectListById",
        params,
        true
      );
      // console.log(res, "应用程序数据回显-----------");
      res.data.forEach((item) => {
        item.configType = "应用程序";

        if (item.osTypeId == 20) {
          item.osTypeId = "Android";
        }
        if (item.osTypeId == 10) {
          item.osTypeId = "iOS";
        }
        this.$set(item, "scopeStr", item.osTypeId);
      });
      this.seleMoveArr = res.data;
      var sumData = [];
      sumData = sumData
        .concat(this.seleDevArr)
        .concat(this.seleComArr)
        .concat(this.seleDocumentArr)
        .concat(this.seleMoveArr)
        .concat(this.seleNoticeArr);
      this.$store.commit("getSelectAssignData", sumData);
    },
    //文档内容数据回显
    async docData(v) {
      var params = {
        currentPage: 1,
        pageSize: 999,
        parentId: this.sessionArr[0].parentId, //this.sessionArr[0].parentId
        name: "",
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        valid: "",
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        params,
        true
      );

      let arr1 = [];
      for (var i = 0; i < res.data.content.length; i++) {
        for (var j = 0; j < v[0]?.idList.length; j++) {
          if (res.data.content[i].id == v[0]?.idList[j]) {
            res.data.content[i].configType = this.value;
            arr1.unshift(res.data.content[i]);

            this.seleDocumentArr = [...arr1];

            this.content.documents = v[0]?.idList;
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
          }
        }
      }
    },
    //通知内容数据回显
    async notData(v) {
      var params = {
        currentPage: 1,
        pageSize: 999,
        searchName: "",
        orderColume: "createTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/notice/getDataInfo",
        params,
        true
      );

      let arr1 = [];
      for (var i = 0; i < res.data.content.length; i++) {
        for (var j = 0; j < v[0]?.idList.length; j++) {
          if (res.data.content[i].id == v[0]?.idList[j]) {
            res.data.content[i].configType = this.value;
            this.$set(res.data.content[i], "name", res.data.content[i].title);
            arr1.unshift(res.data.content[i]);

            this.seleNoticeArr = [...arr1];

            this.content.notices = v[0]?.idList;
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
          }
        }
      }
    },
    //弹框回调事件
    handleClose1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //
    changeContent(v) {
      this.options.forEach((item) => {
        if (v == item.value) {
          this.title = "选择" + item.label;
        }
      });
    },
    //选择分配内容
    async choice() {
      if (this.value == "1" || this.value == "设备策略") {
        this.title = this.$t(
          "Delivery.distributionManagement.SelectDevicePolicy"
        );
        this.outerVisible = true;
        this.$store.commit("setDialogVisibleTitle", "1");
      } else if (this.value == "2" || this.value == "合规策略") {
        this.title = this.$t(
          "Delivery.distributionManagement.SelectComplianceStrategy"
        );
        this.outerVisible = true;
        this.$store.commit("setDialogVisibleTitle", "2");
      } else if (this.value == "3" || this.value == "应用程序") {
        this.title = this.$t("Delivery.distributionManagement.ChooseMobileApp");
        this.outerVisible = true;
        this.$store.commit("setDialogVisibleTitle", "3");
      } else if (this.value == "4" || this.value == "文档内容") {
        this.title = this.$t(
          "Delivery.distributionManagement.SelectDocContent"
        );
        this.outerVisible = true;
        this.$store.commit("setDialogVisibleTitle", "4");
      } else if (this.value == "5" || this.value == "通知内容") {
        this.title = this.$t(
          "Delivery.distributionManagement.SelectNotContent"
        );
        this.outerVisible = true;
        this.$store.commit("setDialogVisibleTitle", "5");
      }
    },
    //判断两个对象是否来自相同的操作系统-----返回true / false
    isSameOS(obj1, obj2) {
      return obj1.scopeStr === obj2.scopeStr;
    },
    //在数组 a 中查找存在与数组 b 中相同的操作系统的对象-----返回obj
    filterSameOSObjects(arrayA, arrayB) {
      // 获取数组 B 中所有的 id，并存储在一个 Set 中
      let idsInBSet = new Set(arrayB.map((obj) => obj.scopeStr));
      // 在数组 A 中查找所有与数组 B 中 id 相同的对象
      let matchingObjects = arrayA.filter((objA) =>
        idsInBSet.has(objA.scopeStr)
      );
      console.log(matchingObjects, "输出与数组 B 中 id 相同的对象"); // 输出与数组 B 中 id 相同的对象
      return matchingObjects;
    },
    //在数组 a 中查找存在与数组 b 中 id 相同的对象
    findMatchingObjects(arrayA, arrayB) {
      // 获取数组 B 中所有的 id，并存储在一个 Set 中
      let idsInBSet = new Set(arrayB.map((obj) => obj.id));
      // 在数组 A 中查找所有与数组 B 中 id 相同的对象
      let matchingObjects = arrayA.filter((objA) => idsInBSet.has(objA.id));
      // console.log(matchingObjects, "输出与数组 B 中 id 相同的对象"); // 输出与数组 B 中 id 相同的对象
      return matchingObjects;
    },
    findMatchingIds(arrayA, arrayB) {
      // 使用 filter 方法过滤出与数组 B 中 id 一致的对象
      let matchingObjects = arrayA.filter((objA) =>
        arrayB.some((objB) => objB.id === objA.id)
      );
      // 返回匹配对象的新数组
      return matchingObjects;
    },
    //判断数组中是否所有的元素都具有相同的scopeStr属性
    isAllSameByOS(array) {
      if (array.length === 0) {
        return []; // 空数组没有type属性可判断
      }
      const firstItemType = array[0].scopeStr; // 获取第一个元素的type
      // 使用Array.prototype.every方法来检查所有元素是否都具有相同的type
      return array.every((item) => item.scopeStr === firstItemType);
    },
    // 判断对象数组中是否存在操作系统相同的元素
    hasDuplicates(array) {
      // 使用 filter 方法获取所有操作系统相同的元素
      let duplicates = array.filter((obj, index) => {
        return (
          array.findIndex((obj2) => obj.scopeStr === obj2.scopeStr) !== index
        );
      });
      // 如果 duplicates 数组的长度不为0，说明存在操作系统相同的元素 如果存在操作系统相同的元素，将输出 true，否则输出 false
      return duplicates.length > 0;
    },
    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
    filterFirstScope(arr) {
      let firstAndroidDevice;
      let firstIOSDevice;
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i].scopeStr, "arr[i].scopeStrAndroid");
        if (arr[i].scopeStr === "Android") {
          firstAndroidDevice = arr[i];

          break;
        }
      }
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i].scopeStr, "arr[i].scopeStriOS");
        if (arr[i].scopeStr === "iOS") {
          firstIOSDevice = arr[i];
          break;
        }
      }
      console.log(firstAndroidDevice, "firstAndroidDevice");
      console.log(firstIOSDevice, "firstIOSDevice");
      let newArrayAndroid = [];
      let newArrayIOS = [];
      let mergedArray2 = [];
      if (firstAndroidDevice != undefined) {
        newArrayAndroid.push(firstAndroidDevice);
        mergedArray2 = [...newArrayAndroid, ...newArrayIOS];
      } else {
        console.log(mergedArray2, "第一个操作系统为Android");

        return mergedArray2;
      }
      if (firstIOSDevice != undefined) {
        newArrayIOS.push(firstIOSDevice);
        mergedArray2 = [...newArrayAndroid, ...newArrayIOS];
      } else {
        console.log(mergedArray2, "第一个操作系统为iOS");

        return mergedArray2;
      }
      console.log(
        mergedArray2,
        "筛选第一个操作系统为Android和第一个操作系统为iOS的元素"
      );
      return mergedArray2;
    },
    // 筛选出具有唯一操作系统的对象
    filterScope(data) {
      const osMap = new Map();
      // 将操作系统作为键，将对应的对象作为值存储在Map中
      data.forEach((obj) => osMap.set(obj.scopeStr, obj));

      // 筛选出具有唯一操作系统的对象
      const uniqueData = Array.from(osMap.values());
      // console.log(uniqueData, "筛选出具有唯一操作系统的对象uniqueData");
      return uniqueData;
    },
    //勾选设备策略数据var deviceBool = false; //默认无重复
    seleContent(data) {
      const arr1 = [];
      const arr2 = [];
      var filteredArray = [];
      this.$store.state.SelectAssignData.map(function (track) {
        if (track.configType !== "设备策略") {
          filteredArray.push(track);
        }
      });

      console.log(filteredArray, "filteredArray");
      if (this.$store.state.SelectAssignData.length == 0) {
        const dataArr = data;
        if (data.length == 1) {
          this.deviceBool = 0;
          data.forEach((item) => {
            this.$set(item, "configType", "设备策略");
            //获取勾选数据的id
            arr1.push(item.id);
          });
          //去重
          this.content.devicePolicies = [...new Set(arr1)];
          this.seleDevArr = data;
          console.log(this.seleDevArr, "this.seleDevArr");
          return this.seleDevArr;
        } else if (data.length == 2) {
          if (this.isSameOS(dataArr[0], dataArr[1])) {
            this.deviceBool = 2;
            this.$set(dataArr[0], "configType", "设备策略");
            //获取勾选数据的id
            arr1.push(dataArr[0].id);
            //去重
            this.content.devicePolicies = [...new Set(arr1)];
            this.seleDevArr = dataArr[0];
            return this.seleDevArr;
          } else {
            this.deviceBool = 0;
            dataArr.forEach((item) => {
              this.$set(item, "configType", "设备策略");
              //获取勾选数据的id
              arr1.push(item.id);
            });
            //去重
            this.content.devicePolicies = [...new Set(arr1)];
            this.seleDevArr = dataArr;
            return this.seleDevArr;
          }
        } else if (data.length > 2) {
          //判断数组里的对象值是否重复
          const unique = (arr) => {
            const res = [];
            const json = {};
            for (let i = 0; i < arr.length; i++) {
              // console.log(json[arr[i].scopeStr], 'json')
              if (!json[arr[i].scopeStr]) {
                res.push(arr[i]);
                json[arr[i].scopeStr] = 1;
              } else {
                this.deviceBool = 2;
              }
            }
            res.forEach((item) => {
              this.$set(item, "configType", "设备策略");
              //获取勾选数据的id
              arr1.push(item.id);
            });
            //去重
            this.content.devicePolicies = [...new Set(arr1)];
            this.seleDevArr = res;
            return this.seleDevArr;
          };
          unique(dataArr);
        } else if (data.length == 0) {
          this.delSeledevFlag = true;
          this.deviceBool = 0;
          this.content.devicePolicies = [];
          this.seleDevArr = data;
          return this.seleDevArr;
        }
      } else if (this.$store.state.SelectAssignData.length > 0) {
        const dataArr = data;
        if (dataArr.length == 0) {
          this.delSeledevFlag = true;
          this.deviceBool = 0;
          this.content.devicePolicies = [];
          this.seleDevArr = data;
          return this.seleDevArr;
        } else if (dataArr.length != 0) {
          //筛选出本地已存数据中的设备策略
          var list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "1" || track.configType == "设备策略") {
              list.push(track);
            }
          });
          //本地已存数据中有设备策略
          if (list.length > 0) {
            let scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((items) => {
              //勾选的数据中与本地已存的数据操作类型相同的数量
              const count = list.map(
                (item1) => item1.scope === items.scope
              ).length;
              if (count < 2) {
                // 找出对象数组A中不相同的操作系统
                if (list.map((s) => s.id).includes(items.id)) {
                  // id不能相同 --------不能勾选同一条数据两次this.deviceBool=1
                  this.deviceBool = 1;
                  this.seleDevArr = this.$store.state.SelectAssignData;
                }
                if (list.map((s) => s.scopeStr).includes(items.scopeStr)) {
                  console.log(list, "cont小于1----操作系统相同同");
                  var arrCont = [...list, ...data];
                  //在本地存储中查找存在与勾选数据中 id 相同的对象
                  if (this.findMatchingObjects(list, data).length > 0) {
                    var newArray = [];
                    if (this.isAllSameByOS(arrCont)) {
                      newArray.push(arrCont[0]);
                      console.log(newArray, "具有相同的scopeStr属性");
                    } else {
                      newArray = this.filterFirstScope(data);
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "设备策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.devicePolicies = [...new Set(arr1)];
                    this.deviceBool = 2;
                    this.seleDevArr = [...arr2, ...filteredArray];
                    return this.seleDevArr;
                  } else {
                    //相反回显数据全部被取消勾选
                    var newArray = [];
                    //新勾选数据大于1条
                    if (data.length > 2) {
                      if (this.hasDuplicates(data)) {
                        this.deviceBool = 2;
                        newArray = this.filterFirstScope(data);
                      } else if (!this.hasDuplicates(data)) {
                        this.deviceBool = 0;
                        newArray = data;
                      }
                    } else if (data.length == 1) {
                      this.deviceBool = 0;
                      newArray = data;
                    } else if (data.length == 2) {
                      if (this.hasDuplicates(data)) {
                        console.log(data, "存在操作系统相同的元素");
                        this.deviceBool = 2;
                        // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                        newArray.push(data[0]);
                      } else if (!this.hasDuplicates(data)) {
                        this.deviceBool = 0;
                        console.log("不存在操作系统相同的元素");
                        newArray = data;
                      }
                    } else {
                      this.deviceBool = 0;
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "设备策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.devicePolicies = [...new Set(arr1)];
                    this.seleDevArr = [...arr2, ...filteredArray];
                    return this.seleDevArr;
                  }
                } else {
                  console.log("cont小于1且id不同操作系统不同");
                  var newArray = [];
                  if (data.length > 2) {
                    this.deviceBool = 2;
                    //  筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = this.filterFirstScope(data);
                  } else if (data.length == 1) {
                    //  筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = data;
                  } else if (data.length == 2) {
                    console.log("yuanye");
                    if (this.hasDuplicates(data)) {
                      console.log("存在操作系统相同的元素");
                      this.deviceBool = 2;
                      // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                      newArray.push(data[0]);
                    } else if (!this.hasDuplicates(data)) {
                      this.deviceBool = 0;
                      console.log("不存在操作系统相同的元素");
                      newArray = data;
                    }
                  } else {
                    this.deviceBool = 0;
                  }
                  newArray.forEach((i) => {
                    //获取勾选数据的id
                    arr1.push(i.id);
                    this.$set(i, "configType", "设备策略");
                    arr2.push(i);
                  });
                  //去重
                  this.content.devicePolicies = [...new Set(arr1)];
                  this.seleDevArr = [...arr2, ...filteredArray];
                  return this.seleDevArr;
                }
              } else {
                var newArray = [];
                var arrCont = [...list, ...data];
                //新勾选数据大于1条
                if (data.length == 1) {
                  this.deviceBool = 0;
                  this.content.devicePolicies = [];
                  this.seleDevArr = data;
                  return this.seleDevArr;
                } else if (data.length == 2) {
                  if (this.hasDuplicates(data)) {
                    console.log("存在操作系统相同的元素111111111111111");
                    this.deviceBool = 2;
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray.push(data[0]);
                    console.log(newArray, "在外边1");
                  } else if (!this.hasDuplicates(data)) {
                    this.deviceBool = 0;
                    console.log("不存在操作系统相同的元素");
                    newArray = data;
                    console.log(newArray, "在外边2");
                  }
                  console.log(newArray, "在外边");
                  newArray.forEach((i) => {
                    //获取勾选数据的id
                    arr1.push(i.id);
                    this.$set(i, "configType", "设备策略");
                    arr2.push(i);
                  });
                  //去重
                  this.content.devicePolicies = [...new Set(arr1)];
                  this.seleDevArr = [...arr2, ...filteredArray];
                  return this.seleDevArr;
                } else if (data.length > 2) {
                  //在数组 a 中查找存在与数组 b 中相同的操作系统的对象
                  if (this.filterSameOSObjects(data, list).length > 1) {
                    console.log(
                      this.filterFirstScope(data),
                      "筛选第一个操作系统为Android和第一个操作系统为iOS的元素"
                    );
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    if (this.isAllSameByOS(data)) {
                      newArray.push(data[0]);
                      console.log(newArray, "具有相同的scopeStr属性");
                    } else {
                      newArray = this.filterFirstScope(data);
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "设备策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.devicePolicies = [...new Set(arr1)];
                    this.deviceBool = 2;
                    this.seleDevArr = [...arr2, ...filteredArray];
                    return this.seleDevArr;
                  }
                  if (this.filterSameOSObjects(data, list).length == 1) {
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = this.filterSameOSObjects(scopeStrArr, list);
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "设备策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.devicePolicies = [...new Set(arr1)];
                    this.deviceBool = 2;
                    this.seleDevArr = [...arr2, ...filteredArray];
                    return this.seleDevArr;
                  }
                  if (this.filterSameOSObjects(data, list).length == 0) {
                    //新勾选数据大于1条
                    if (data.length > 2) {
                      if (this.hasDuplicates(data)) {
                        this.deviceBool = 2;
                        newArray = this.filterFirstScope(data);
                      } else if (!this.hasDuplicates(data)) {
                        this.deviceBool = 0;
                        newArray = data;
                      }
                    } else if (data.length == 1) {
                      this.deviceBool = 0;
                      newArray = data;
                    } else if (data.length == 2) {
                      var newArray = [];
                      if (this.hasDuplicates(data)) {
                        console.log("存在操作系统相同的元素");
                        this.deviceBool = 2;
                        // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                        newArray.push(data[0]);
                      } else if (!this.hasDuplicates(data)) {
                        this.deviceBool = 0;
                        console.log("不存在操作系统相同的元素");
                        newArray = data;
                      }
                    } else {
                      this.deviceBool = 0;
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "设备策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.devicePolicies = [...new Set(arr1)];
                    this.seleDevArr = [...arr2, ...filteredArray];
                    return this.seleDevArr;
                  }
                } else {
                  this.deviceBool = 0;
                  this.content.devicePolicies = [];
                  this.seleDevArr = data;
                  return this.seleDevArr;
                }
              }
            });
          } else if (list.length == 0) {
            var newArray = [];
            if (this.hasDuplicates(data)) {
              console.log("存在操作系统相同的元素");
              this.deviceBool = 2;
              // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
              newArray = this.filterFirstScope(data);
            } else if (!this.hasDuplicates(data)) {
              this.deviceBool = 0;
              console.log("不存在操作系统相同的元素");
              newArray = data;
            }
            newArray.forEach((i) => {
              //获取勾选数据的id
              arr1.push(i.id);
              this.$set(i, "configType", "设备策略");
              arr2.push(i);
            });
            //去重
            this.content.devicePolicies = [...new Set(arr1)];
            this.seleDevArr = [...arr2, ...filteredArray];
            return this.seleDevArr;
          }
        }
      }
    },
    //勾选合规策略数据
    seleCompList(data) {
      console.log(this.$store.state.SelectAssignData, data, "勾选合规策略数据");
      const arr1 = [];
      const arr2 = [];
      var filteredArray = [];
      this.$store.state.SelectAssignData.map(function (track) {
        if (track.configType !== "合规策略") {
          filteredArray.push(track);
        }
      });
      if (this.$store.state.SelectAssignData.length == 0) {
        const dataArr = data;
        if (data.length == 1) {
          this.compliceBool = 0;
          data.forEach((item) => {
            this.$set(item, "configType", "合规策略");
            //获取勾选数据的id
            arr1.push(item.id);
          });
          //去重

          this.content.compliancePolicies = [...new Set(arr1)];
          this.seleComArr = data;
          return this.seleComArr;
        } else if (data.length == 2) {
          if (this.isSameOS(dataArr[0], dataArr[1])) {
            this.compliceBool = 2;
            this.$set(dataArr[0], "configType", "合规策略");
            //获取勾选数据的id
            arr1.push(dataArr[0].id);
            //去重
            this.content.compliancePolicies = [...new Set(arr1)];
            this.seleComArr = dataArr[0];
            return this.seleComArr;
          } else {
            this.compliceBool = 0;
            dataArr.forEach((item) => {
              this.$set(item, "configType", "合规策略");
              //获取勾选数据的id
              arr1.push(item.id);
            });
            //去重
            this.content.compliancePolicies = [...new Set(arr1)];
            this.seleComArr = dataArr;
            return this.seleComArr;
          }
        } else if (data.length > 2) {
          //判断数组里的对象值是否重复
          const unique = (arr) => {
            const res = [];
            const json = {};
            for (let i = 0; i < arr.length; i++) {
              if (!json[arr[i].scopeStr]) {
                res.push(arr[i]);
                json[arr[i].scopeStr] = 1;
              } else {
                // scopeStr不能相同  --------不能勾选同一操作系统数据两次this.compliceBool=2
                this.compliceBool = 2;
              }
            }
            res.forEach((item) => {
              this.$set(item, "configType", "合规策略");
              //获取勾选数据的id
              arr1.push(item.id);
              arr2.push(i);
            });
            //去重
            this.content.compliancePolicies = [...new Set(arr1)];
            this.seleComArr = res;
            return this.seleComArr;
          };
          unique(dataArr);
        }
        if (data.length == 0) {
          this.delSeleComFlag = true;
          this.compliancePolicies = 0;
          this.content.compliancePolicies = [];
          this.seleComArr = data;
          return this.seleComArr;
        }
      } else if (this.$store.state.SelectAssignData.length > 0) {
        const dataArr = data;
        if (dataArr.length == 0) {
          this.delSeleComFlag = true;
          this.compliceBool = 0;
          this.content.compliancePolicies = [];
          this.seleComArr = data;
          return this.seleComArr;
        } else if (dataArr.length != 0) {
          //筛选出本地已存数据中的合规策略
          var list2 = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "2" || track.configType == "合规策略") {
              list2.push(track);
            }
          });
          //本地已存数据中有合规策略
          if (list2.length > 0) {
            let scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((items) => {
              //勾选的数据中与本地已存的数据操作类型相同的数量
              const count = list2.map(
                (item1) => item1.scope === items.scope
              ).length;
              if (count < 2) {
                // 找出对象数组A中不相同的操作系统
                if (list2.map((s) => s.id).includes(items.id)) {
                  // id不能相同 --------不能勾选同一条数据两次
                  this.compliceBool = 1;
                  this.seleComArr = this.$store.state.SelectAssignData;
                }
                if (list2.map((s) => s.scopeStr).includes(items.scopeStr)) {
                  console.log("cont小于1----操作系统相同同");
                  var arrCont = [...list2, ...data];
                  //在本地存储中查找存在与勾选数据中 id 相同的对象
                  if (this.findMatchingObjects(list2, data).length > 0) {
                    var newArray = [];
                    if (this.isAllSameByOS(arrCont)) {
                      newArray.push(arrCont[0]);
                      console.log(newArray, "具有相同的scopeStr属性");
                    } else {
                      newArray = this.filterFirstScope(data);
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "合规策略");
                      arr2.push(i);
                    });
                    this.content.compliancePolicies = [...new Set(arr1)];
                    this.compliceBool = 2;
                    this.seleComArr = [...arr2, ...filteredArray];
                    return this.seleComArr;
                  } else {
                    //相反回显数据全部被取消勾选
                    var newArray = [];
                    //新勾选数据大于1条
                    if (data.length > 2) {
                      if (this.hasDuplicates(data)) {
                        this.compliceBool = 2;
                        newArray = this.filterFirstScope(data);
                      } else if (!this.hasDuplicates(data)) {
                        this.compliceBool = 0;
                        newArray = data;
                      }
                    } else if (data.length == 1) {
                      this.compliceBool = 0;
                      newArray = data;
                    } else if (data.length == 2) {
                      if (this.hasDuplicates(data)) {
                        console.log(data, "存在操作系统相同的元素");
                        this.compliceBool = 2;
                        // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                        newArray.push(data[0]);
                      } else if (!this.hasDuplicates(data)) {
                        this.compliceBool = 0;
                        console.log("不存在操作系统相同的元素");
                        newArray = data;
                      }
                    } else {
                      this.compliceBool = 0;
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "合规策略");
                      arr2.push(i);
                    });
                    this.content.compliancePolicies = [...new Set(arr1)];
                    this.seleComArr = [...arr2, ...filteredArray];
                    return this.seleComArr;
                  }
                } else {
                  var newArray = [];
                  if (data.length > 2) {
                    this.compliceBool = 2;
                    //  筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = this.filterFirstScope(data);
                  } else if (data.length == 1) {
                    //  筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = data;
                  } else if (data.length == 2) {
                    console.log("yuanye");
                    if (this.hasDuplicates(data)) {
                      console.log("存在操作系统相同的元素");
                      this.compliceBool = 2;
                      // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                      newArray.push(data[0]);
                    } else if (!this.hasDuplicates(data)) {
                      this.compliceBool = 0;
                      console.log("不存在操作系统相同的元素");
                      newArray = data;
                    }
                  } else {
                    this.compliceBool = 0;
                  }
                  newArray.forEach((i) => {
                    //获取勾选数据的id
                    arr1.push(i.id);
                    this.$set(i, "configType", "合规策略");
                    arr2.push(i);
                  });
                  //去重
                  this.content.compliancePolicies = [...new Set(arr1)];
                  this.seleComArr = [...arr2, ...filteredArray];
                  return this.seleComArr;
                }
              } else {
                var newArray = [];
                //新勾选数据大于1条
                if (data.length == 1) {
                  this.compliceBool = 0;
                  this.content.devicePolicies = [];
                  this.seleComArr = data;
                  return this.seleComArr;
                } else if (data.length == 2) {
                  if (this.hasDuplicates(data)) {
                    console.log("存在操作系统相同的元素");
                    this.compliceBool = 2;
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray.push(data[0]);
                    console.log(newArray, "在外边1");
                  } else if (!this.hasDuplicates(data)) {
                    this.compliceBool = 0;
                    console.log("不存在操作系统相同的元素");
                    newArray = data;
                    console.log(newArray, "在外边2");
                  }
                  console.log(newArray, "在外边");
                  newArray.forEach((i) => {
                    //获取勾选数据的id
                    arr1.push(i.id);
                    this.$set(i, "configType", "合规策略");
                    arr2.push(i);
                  });
                  //去重
                  this.content.devicePolicies = [...new Set(arr1)];
                  this.seleComArr = [...arr2, ...filteredArray];
                  return this.seleComArr;
                } else if (data.length > 2) {
                  //在数组 a 中查找存在与数组 b 中相同的操作系统的对象
                  if (this.filterSameOSObjects(data, list2).length > 1) {
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    if (this.isAllSameByOS(data)) {
                      newArray.push(data[0]);
                      console.log(newArray, "具有相同的scopeStr属性");
                    } else {
                      newArray = this.filterFirstScope(data);
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "合规策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.compliancePolicies = [...new Set(arr1)];
                    this.seleComArr = [...arr2, ...filteredArray];
                    this.compliceBool = 2;
                    return this.seleComArr;
                  }
                  if (this.filterSameOSObjects(data, list2).length == 1) {
                    // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
                    newArray = this.filterSameOSObjects(scopeStrArr, list2);
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "合规策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.compliancePolicies = [...new Set(arr1)];
                    this.seleComArr = [...arr2, ...filteredArray];
                    this.compliceBool = 2;
                    return this.seleComArr;
                  }
                  if (this.filterSameOSObjects(data, list2).length == 0) {
                    //新勾选数据大于1条
                    if (data.length > 1) {
                      if (this.hasDuplicates(data)) {
                        this.compliceBool = 2;
                        newArray = this.filterFirstScope(data);
                      } else if (!this.hasDuplicates(data)) {
                        this.compliceBool = 0;
                        newArray = data;
                      }
                    } else if (data.length == 1) {
                      this.compliceBool = 0;
                      newArray = data;
                    } else {
                      this.compliceBool = 0;
                    }
                    newArray.forEach((i) => {
                      //获取勾选数据的id
                      arr1.push(i.id);
                      this.$set(i, "configType", "合规策略");
                      arr2.push(i);
                    });
                    //去重
                    this.content.compliancePolicies = [...new Set(arr1)];
                    this.seleComArr = [...arr2, ...filteredArray];
                    return this.seleComArr;
                  }
                } else {
                  this.compliceBool = 0;
                  this.content.compliancePolicies = [];
                  this.seleComArr = data;
                  return this.seleComArr;
                }
              }
            });
          } else if (list2.length == 0) {
            var newArray = [];
            if (this.hasDuplicates(data)) {
              this.compliceBool = 2;
              newArray = this.filterFirstScope(data);
            } else if (!this.hasDuplicates(data)) {
              this.compliceBool = 0;
              newArray = data;
            }
            newArray.forEach((i) => {
              //获取勾选数据的id
              arr1.push(i.id);
              this.$set(i, "configType", "合规策略");
              arr2.push(i);
            });
            //去重
            this.content.compliancePolicies = [...new Set(arr1)];
            this.seleComArr = [...arr2, ...filteredArray];
            return this.seleMoveArr;
          }
        }
      }
    },
    //勾选应用程序数据
    seleMobileList(data) {
      let arr1 = [];
      let arr2 = [];
      var filteredArray = [];
      this.$store.state.SelectAssignData.map(function (track) {
        if (track.configType !== "应用程序") {
          filteredArray.push(track);
        }
      });
      if (this.$store.state.SelectAssignData.length > 0) {
        var list = [];
        this.$store.state.SelectAssignData.map(function (track) {
          if (
            track.configType == "3" ||
            track.configType == "0" ||
            track.configType == "应用程序"
          ) {
            list.push(track);
          }
        });
        if (list.length > 0) {
          if (data.length > 0) {
            // console.log(this.findMatchingObjects(list, data),'应用程序');
            // if (this.findMatchingObjects(list, data).length > 0) {
            //   this.moveCount++;
            // } else {
            //   this.moveCount = 0;
            // }
            var scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((item) => {
              //获取勾选数据的id
              arr1.push(item.id);
              //转译分类
              this.options.forEach((i) => {
                if (item.askStatus == i.value) {
                  item.askStatus = i.label;
                }
              });
              this.$set(item, "configType", "应用程序");
              this.$set(item, "name", item.appName);
              this.$set(item, "scopeStr", item.osTypeId);
              this.$set(item, "versionName", item.versionName);
              this.$set(item, "id", item.id);
              arr2.push(item);
            });
            this.content.apps = [...new Set(arr1)];
            this.seleMoveArr = [...arr2, ...filteredArray];
            return this.seleMoveArr;
          }
          if (data.length === 0) {
            this.delSeleMovFlag = true;
            this.moveCount = 0; //不提示
            this.content.apps = [];
            this.seleMoveArr = data;
            return this.seleMoveArr;
          }
        } else {
          if (data.length > 0) {
            let scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((item) => {
              //获取勾选数据的id
              arr1.push(item.id);
              //转译分类
              this.options.forEach((i) => {
                if (item.askStatus == i.value) {
                  item.askStatus = i.label;
                }
              });
              this.$set(item, "configType", "应用程序");
              this.$set(item, "name", item.appName);
              this.$set(item, "scopeStr", item.osTypeId);
              this.$set(item, "versionName", item.versionName);
              this.$set(item, "id", item.id);
              arr2.push(item);
            });
            this.moveCount = 0; //不提示
            this.content.apps = [...new Set(arr1)];
            this.seleMoveArr = [...arr2, ...filteredArray];
            return this.seleMoveArr;
          }
          if (data.length === 0) {
            this.delSeleMovFlag = true;
            this.moveCount = 0; //不提示
            this.content.apps = [];
            this.seleMoveArr = data;
            return this.seleMoveArr;
          }
        }
      } else if (this.$store.state.SelectAssignData.length == 0) {
        var scopeStrArr = data.map((i) => i);
        scopeStrArr.forEach((item) => {
          //获取勾选数据的id
          arr1.push(item.id);
          //转译分类
          this.options.forEach((i) => {
            if (item.askStatus == i.value) {
              item.askStatus = i.label;
            }
          });
          this.$set(item, "configType", "应用程序");
          this.$set(item, "name", item.appName);
          this.$set(item, "scopeStr", item.osTypeId);
          this.$set(item, "versionName", item.versionName);
          this.$set(item, "id", item.id);
          arr2.push(item);
        });
        //去重
        this.content.apps = [...new Set(arr1)];
        this.moveCount = 0; //不提示
        this.seleMoveArr = [...arr2, ...filteredArray];
        return this.seleMoveArr;
      }
    },
    //勾选文档内容数据
    seleDocumentList(data) {
      // console.log(data, "data勾选文档内容数据");
      let arr1 = [];
      let arr2 = [];
      var filteredArray = [];
      this.$store.state.SelectAssignData.map(function (track) {
        if (track.configType !== "文档内容") {
          filteredArray.push(track);
        }
      });
      if (this.$store.state.SelectAssignData.length > 0) {
        var list = [];
        this.$store.state.SelectAssignData.map(function (track) {
          if (track.configType == "4" || track.configType == "文档内容") {
            list.push(track);
          }
        });
        if (list.length > 0) {
          if (data.length > 0) {
            // if (this.findMatchingObjects(list, data).length > 0) {
            //   this.documentBool++;
            // } else {
            //   this.documentBool = 0;
            // }
            var scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((i) => {
              this.$set(i, "id", i.id);
              this.$set(i, "configType", "文档内容");
              //获取勾选数据的id
              arr1.push(i.id);
              arr2.push(i);
            });
            this.content.documents = [...new Set(arr1)];
            this.seleDocumentArr = [...arr2, ...filteredArray];
            return this.seleDocumentArr;
          }
          if (data.length === 0) {
            this.delSeleDocFlag = true;
            this.documentBool = 0;
            this.content.documents = [];
            this.seleDocumentArr = data;
            return this.seleDocumentArr;
          }
        } else {
          if (data.length > 0) {
            var scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((i) => {
              this.$set(i, "id", i.id);
              this.$set(i, "configType", "文档内容");
              //获取勾选数据的id
              arr1.push(i.id);
              arr2.push(i);
            });
            this.documentBool = 0;
            this.content.documents = [...new Set(arr1)];
            this.seleDocumentArr = [...arr2, ...filteredArray];
            return this.seleDocumentArr;
          }
          if (data.length === 0) {
            this.delSeleDocFlag = true;
            this.documentBool = 0;
            this.content.documents = [];
            this.seleDocumentArr = data;
            return this.seleDocumentArr;
          }
        }
      } else if (this.$store.state.SelectAssignData.length == 0) {
        var scopeStrArr = data.map((i) => i);
        scopeStrArr.forEach((i) => {
          this.$set(i, "id", i.id);
          this.$set(i, "configType", "文档内容");
          //获取勾选数据的id
          arr1.push(i.id);
          arr2.push(i);
        });
        this.documentBool = 0;
        this.content.documents = [...new Set(arr1)];
        this.seleDocumentArr = [...arr2, ...filteredArray];
        return this.seleDocumentArr;
      }
    },
    //勾选通知内容数据
    seleNoticeList(data) {
      let arr1 = [];
      let arr2 = [];
      var filteredArray = [];
      this.$store.state.SelectAssignData.map(function (track) {
        if (track.configType !== "通知内容") {
          filteredArray.push(track);
        }
      });
      if (this.$store.state.SelectAssignData.length > 0) {
        var list = [];
        this.$store.state.SelectAssignData.map(function (track) {
          if (track.configType == "5" || track.configType == "通知内容") {
            list.push(track);
          }
        });
        if (list.length > 0) {
          if (data.length > 0) {
            // if (this.findMatchingObjects(list, data).length > 0) {
            //   this.noticeBool = true;
            // } else {
            //   this.noticeBool = false; //默认无重复勾选应用程序数据操作系统
            // }
            var scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((item) => {
              this.$set(item, "configType", "通知内容");
              this.$set(item, "name", item.title);
              //获取勾选数据的id
              arr1.push(item.id);
              arr2.push(item);
            });
            this.content.notices = arr1;
            this.seleNoticeArr = [...arr2, ...filteredArray];
            return this.seleNoticeArr;
          }
          if (data.length === 0) {
            this.delSeleNotFlag = true;
            this.noticeBool = false; //不提示
            this.content.notices = [];
            this.seleNoticeArr = data;
            return this.seleNoticeArr;
          }
        } else {
          if (data.length > 0) {
            let scopeStrArr = data.map((i) => i);
            scopeStrArr.forEach((item) => {
              this.$set(item, "configType", "通知内容");
              this.$set(item, "name", item.title);
              //获取勾选数据的id
              arr1.push(item.id);
              arr2.push(item);
            });
            this.content.notices = arr1;
            this.seleNoticeArr = [...arr2, ...filteredArray];
            return this.seleNoticeArr;
          }
          if (data.length === 0) {
            this.delSeleNotFlag = true;
            this.noticeBool = false; //不提示
            this.content.notices = [];
            this.seleNoticeArr = data;
            return this.seleNoticeArr;
          }
        }
      } else if (this.$store.state.SelectAssignData.length == 0) {
        var scopeStrArr = data.map((i) => i);
        scopeStrArr.forEach((item) => {
          arr1.push(item.id);
          this.$set(item, "configType", "通知内容");
          this.$set(item, "name", item.title);
          arr2.push(item);
        });
        this.content.notices = arr1;
        this.seleNoticeArr = [...arr2, ...filteredArray];
        return this.seleNoticeArr;
      }
    },
    //分配内容操作列删除
    async del(v) {
      console.log(this.seleDevArr, "this.seleDevArr");
      let rows = v.row;
      this.$store.commit("delSelectAssignData", [rows]);
      if (rows.configType == "设备策略" || rows.configType == "1") {
        this.$store.commit("deled", rows.id); //被删除的已勾选的分配内容数据
        const matchingObjectsID = this.content.devicePolicies.filter(
          (item) => item !== rows.id
        );
        this.content.devicePolicies = matchingObjectsID;
        // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据
        const matchingObjects = this.seleDevArr.filter(
          (item) => item.id !== rows.id
        );
        this.seleDevArr = matchingObjects;

        this.deviceBool = 0;
        console.log(
          this.$store.state.SelectAssignData,
          this.content.devicePolicies,
          "设备策略删除"
        );
      }
      if (rows.configType == "合规策略" || rows.configType == "2") {
        this.$store.commit("deled", rows.id); //被删除的已勾选的分配内容数据
        // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据
        const matchingObjects = this.seleComArr.filter(
          (item) => item.id !== rows.id
        );
        this.seleComArr = matchingObjects;
        const matchingObjectsID = this.content.compliancePolicies.filter(
          (item) => item !== rows.id
        );
        this.content.compliancePolicies = matchingObjectsID;
        this.compliceBool = 0;
      }
      if (rows.configType == "应用程序" || rows.configType == "3") {
        this.$store.commit("deled", rows.id); //被删除的已勾选的分配内容数据
        // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据
        const matchingObjects = this.seleMoveArr.filter(
          (item) => item.id !== rows.id
        );
        this.seleMoveArr = matchingObjects;
        const matchingObjectsID = this.content.apps.filter(
          (item) => item !== rows.id
        );
        this.content.apps = matchingObjectsID;
      }
      if (rows.configType == "文档内容" || rows.configType == "4") {
        this.$store.commit("deled", rows.id); //被删除的已勾选的分配内容数据
        // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据
        const matchingObjects = this.seleDocumentArr.filter(
          (item) => item.id !== rows.id
        );
        this.seleDocumentArr = matchingObjects;
        const matchingObjectsID = this.content.documents.filter(
          (item) => item !== rows.id
        );
        this.content.documents = matchingObjectsID;
      }
      if (rows.configType == "通知内容" || rows.configType == "5") {
        this.$store.commit("deled", rows.id); //被删除的已勾选的分配内容数据
        // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据
        const matchingObjects = this.seleNoticeArr.filter(
          (item) => item.id !== rows.id
        );
        this.seleNoticeArr = matchingObjects;
        const matchingObjectsID = this.content.notices.filter(
          (item) => item !== rows.id
        );
        this.content.notices = matchingObjectsID;
      }
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
      this.$store.commit("setDialogVisibleTitle", "");
      this.claerCheck();
    },
    claerCheck() {
      console.log("claerCheck");
      if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectDevicePolicy")
      ) {
        //清空查询输入框
        this.$refs.devComponent.input = "";
        this.$refs.devComponent.$refs.contentList.clearSelection();
        this.seleDevArr = [];
      } else if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectComplianceStrategy")
      ) {
        //清空查询输入框
        this.$refs.heComponent.input = "";
        this.$refs.heComponent.$refs.contentList.clearSelection();
        this.seleComArr = [];
      } else if (
        this.title == this.$t("Delivery.distributionManagement.ChooseMobileApp")
      ) {
        //清空查询输入框
        this.$refs.moComponent.input = "";
        this.$refs.moComponent.$refs.contentList.clearSelection();
        this.seleMoveArr = [];
      } else if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectDocContent")
      ) {
        //清空查询输入框
        this.$refs.doComponent.input = "";
        this.$refs.doComponent.$refs.contentList.clearSelection();
        this.seleDocumentArr = [];
      } else if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectNotContent")
      ) {
        //清空查询输入框
        this.$refs.noComponent.input = "";
        this.$refs.noComponent.$refs.contentList.clearSelection();
        this.seleNoticeArr = [];
      }
    },
    //加入按钮
    async determine() {
      // console.log(this.seleDevArr, this.seleComArr, "this.seleDevArr");
      if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectDevicePolicy")
      ) {
        if (this.seleDevArr.length === 0 && this.delSeledevFlag === true) {
          // 筛选出本地已存数据中的设备策略
          var del_list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "1" || track.configType == "设备策略") {
              del_list.push(track);
            }
          });
          this.$store.commit("delSelectAssignData", del_list);
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr)
            .concat(this.$store.state.SelectAssignData);
          this.$store.commit("getSelectAssignData", sumData);
          this.deviceBool = 0;
          this.outerVisible = false;
          return false;
        }
        if (this.seleDevArr.length <= 0 && !this.sessionArr) {
          // alert("1111");
          // this.visibaelFlag = true
          // this.titleInfo = this.$t('Delivery.distributionManagement.SelectAllocateContent')
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateContent"
            ),
            type: "error",
            offset: 100,
          });
        }
        if (this.deviceBool == 1) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.DevicePolicyAlreadyExists"
            ),
            type: "error",
            offset: 100,
          });
          this.$refs.devComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr);
          this.$store.commit("getSelectAssignData", sumData);
          this.deviceBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.deviceBool == 2) {
          console.log(this.deviceBool == 2, "contentOperating");
          // console.log(this.seleDevArr, "this.seleDevArr");
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.contentOperating"
            ),
            type: "error",
            offset: 100,
          });
          this.$refs.devComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr);
          this.$store.commit("getSelectAssignData", sumData);
          this.deviceBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.deviceBool == 3) {
          console.log(this.deviceBool == 3, "SameDevicePolicy");
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SameDevicePolicy"
            ),
            type: "error",
            offset: 100,
          });
          this.$refs.devComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr);
          this.$store.commit("getSelectAssignData", sumData);
          this.deviceBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.deviceBool == 0) {
          this.addMoveData();
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          this.$refs.devComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
        }
      }
      if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectComplianceStrategy")
      ) {
        if (this.seleComArr.length === 0 && this.delSeleComFlag === true) {
          // 筛选出本地已存数据中的设备策略
          var del_list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "2" || track.configType == "合规策略") {
              del_list.push(track);
            }
          });
          this.$store.commit("delSelectAssignData", del_list);
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr)
            .concat(this.$store.state.SelectAssignData);
          this.$store.commit("getSelectAssignData", sumData);
          this.compliceBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.seleComArr.length <= 0 && !this.sessionArr) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateContent"
            ),
            type: "error",
            offset: 100,
          });
        }

        if (this.compliceBool == 1) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.compPolicyAlreadyExists"
            ),
            type: "error",
            offset: 100,
          });
          this.$refs.heComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr);
          this.$store.commit("getSelectAssignData", sumData);
          this.compliceBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.compliceBool == 2) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SameComplianceStrategy"
            ),
            type: "error",
            offset: 100,
          });
          this.$refs.heComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr);
          this.$store.commit("getSelectAssignData", sumData);
          this.compliceBool = 0;
          this.outerVisible = false;
          return false;
        }
        if (this.compliceBool == 0) {
          console.log(this.seleDevArr, "this.compliceBool == 0");
          this.addMoveData();
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          this.$refs.heComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
        }
      }
      if (
        this.title == this.$t("Delivery.distributionManagement.ChooseMobileApp")
      ) {
        console.log(this.delSeleMovFlag, "this.delSeleMovFlag");
        if (this.seleMoveArr.length === 0 && this.delSeleMovFlag === true) {
          // 筛选出本地已存数据中的设备策略
          var del_list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "3" || track.configType == "应用程序") {
              del_list.push(track);
            }
          });
          this.$store.commit("delSelectAssignData", del_list);
          console.log(this.$store.state.SelectAssignData, "应用程序-------");
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr)
            .concat(this.$store.state.SelectAssignData);
          console.log(sumData, "sumData");
          this.$store.commit("getSelectAssignData", sumData);
          this.moveCount = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.seleMoveArr.length <= 0 && !this.sessionArr) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateContent"
            ),
            type: "error",
            offset: 100,
          });
        } else {
          // this.addMoveData();
          if (this.moveCount != 0) {
            this.$message({
              message: "已存在相同应用",
              type: "error",
              offset: 100,
            });
            // console.log(this.seleMoveArr, "选择应用程序this.seleMoveArr");
            this.$refs.moComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
            //选中数据id存到vuex中后边表格展示需要
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
            this.moveCount = 0;
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            return false;
          }
          if (this.moveCount == 0) {
            this.addMoveData();
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            this.$refs.moComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          }
        }
      }
      if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectDocContent")
      ) {
        if (this.seleDocumentArr.length === 0 && this.delSeleDocFlag === true) {
          // 筛选出本地已存数据中的设备策略
          var del_list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "4" || track.configType == "文档内容") {
              del_list.push(track);
            }
          });
          this.$store.commit("delSelectAssignData", del_list);
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr)
            .concat(this.$store.state.SelectAssignData);
          this.$store.commit("getSelectAssignData", sumData);
          this.documentBool = 0;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.seleDocumentArr.length <= 0 && !this.sessionArr) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateContent"
            ),
            type: "error",
            offset: 100,
          });
        } else {
          if (this.documentBool != 0) {
            // console.log(this.seleDocumentArr, "this.seleDocumentArr");
            this.$message({
              message: "已存在相同文档",
              type: "error",
              offset: 100,
            });
            this.$refs.doComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
            //选中数据id存到vuex中后边表格展示需要
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            this.$store.commit("getSelectAssignData", sumData);
            this.documentBool = 0;
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            return false;
          }
          if (this.documentBool == 0) {
            this.addMoveData();
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            this.$refs.doComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          }
        }
      }
      if (
        this.title ==
        this.$t("Delivery.distributionManagement.SelectNotContent")
      ) {
        if (this.seleNoticeArr.length === 0 && this.delSeleNotFlag === true) {
          // 筛选出本地已存数据中的设备策略
          var del_list = [];
          this.$store.state.SelectAssignData.map(function (track) {
            if (track.configType == "5" || track.configType == "通知内容") {
              del_list.push(track);
            }
          });
          this.$store.commit("delSelectAssignData", del_list);
          //选中数据id存到vuex中后边表格展示需要
          var sumData = [];
          sumData = sumData
            .concat(this.seleDevArr)
            .concat(this.seleComArr)
            .concat(this.seleDocumentArr)
            .concat(this.seleMoveArr)
            .concat(this.seleNoticeArr)
            .concat(this.$store.state.SelectAssignData);
          this.$store.commit("getSelectAssignData", sumData);
          this.noticeBool = false;
          this.outerVisible = false;
          this.$store.commit("setDialogVisibleTitle", "");
          return false;
        }
        if (this.seleNoticeArr.length <= 0 && !this.sessionArr) {
          this.$message({
            message: this.$t(
              "Delivery.distributionManagement.SelectAllocateContent"
            ),
            type: "error",
            offset: 100,
          });
        } else {
          if (this.noticeBool) {
            this.$message({
              message: "已存在相同通知",
              type: "error",
              offset: 100,
            });
            this.$refs.noComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
            //选中数据id存到vuex中后边表格展示需要
            var sumData = [];
            sumData = sumData
              .concat(this.seleDevArr)
              .concat(this.seleComArr)
              .concat(this.seleDocumentArr)
              .concat(this.seleMoveArr)
              .concat(this.seleNoticeArr);
            // .concat(this.seleDesktopArr);
            this.$store.commit("getSelectAssignData", sumData);
            this.noticeBool = true;
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            return false;
          }
          if (!this.noticeBool) {
            this.addMoveData();
            this.outerVisible = false;
            this.$store.commit("setDialogVisibleTitle", "");
            this.$refs.noComponent.$refs.contentList.clearSelection(); //清除表格勾选状态
          }
        }
      }
    },
    //加入跳转传递数据
    async addMoveData() {
      // 选中数据id存到vuex中后边表格展示需要
      var sumData = [];
      sumData = sumData
        .concat(this.seleDevArr)
        .concat(this.seleComArr)
        .concat(this.seleDocumentArr)
        .concat(this.seleMoveArr)
        .concat(this.seleNoticeArr);
      this.$store.commit("getSelectAssignData", sumData);
      this.$emit("tableContent", this.content);
      this.echoData(); //分配回显接口
      // this.claerCheck()
    },
    //分配回显
    async echoData() {
      var params = {
        devicePolicies: [...new Set(this.content.devicePolicies)],
        compliancePolicies: [...new Set(this.content.compliancePolicies)],
        apps: [...new Set(this.content.apps)],
        documents: [...new Set(this.content.documents)],
        notices: [...new Set(this.content.notices)],
      };
      // console.log(params, "加入数据回显参数");
      await this.$axios
        .post("/httpServe/delivery/tos", params, true)
        .then((res) => {
          // console.log(res.data, "分配回显");
          this.$store.commit(
            "groupDefaultChecked",
            res.data.groupList?.content
          );
          this.$store.commit("userDefaultChecked", res.data.userList);
          this.$store.commit("deviceDefaultChecked", res.data.deviceList);
          this.$store.commit("userGroupDefaultChecked", res.data.userGroupList); //用户组默认勾选的id---------V6.1.3
          this.$store.commit(
            "deviceGroupDefaultChecked",
            res.data.deviceGroupList
          ); //设备组默认勾选的id---------V6.1.3
          //默认上次已经选中的
          // this.$emit('defaultCheck', res.data)
        });
    },
    //清除表格数据
    clearTable() {
      this.tableContent = [];
    },
    //关闭按钮
    cancel() {
      this.outerVisible = false;
      this.$store.commit("setDialogVisibleTitle", "");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}

// 暂无数据
::v-deep .bigBox .el-table__empty-block {
  width: 9% !important;
}
</style>
