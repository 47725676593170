<template>
  <div style="height: 100%">
    
      <!-- 查询框 -->
      <el-row style="height: 53px; line-height: 40px; margin-top: 5px">
        <el-input
          v-model="searchTable"
          size="mini"
          type="text"
          :placeholder="$t('Delivery.distributionManagement.groupPlaceholder')"
          style="width: 200px"
          @click="onSearchTable"
          @keypress.enter.native="onSearchTable"
          @keypress.native="btKeyUp"
        >
          <i
            @click="onSearchTable"
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer"
          ></i>
        </el-input>
        <el-button
          type="primary"
          size="mini"
          @click="onSearchTable"
          style="margin-left: 4px"
          >{{ $t("Delivery.distributionManagement.query") }}</el-button
        >
      </el-row>
      <el-row
        :gutter="4"
        style="
          margin-left: 0px;
          margin-right: 2px;
          height: 330px;
          overflow: auto;
        "
      >
        <el-table
          stripe
          row-key="id"
          ref="multipleTable"
          fit
          class="sort_table"
          @select="handChange"
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
          tooltip-effect="dark"
          :data="
            tableList.slice(
              (page.currentPage - 1) * page.pageSize,
              page.currentPage * page.pageSize
            )
          "
          style="width: 100%; font-size: 12px"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
        >
          <el-table-column
            type="selection"
            align="center"
            :show-overflow-tooltip="true"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="groupName"
            :show-overflow-tooltip="true"
            :label="$t('Delivery.distributionManagement.DepartmentName')"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="parentName"
            :show-overflow-tooltip="true"
            :label="$t('Delivery.distributionManagement.SuperiorDepartment')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.parentName">
                {{ scope.row.parentName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <Pagination
        :page="page.currentPage"
        :limit="page.pageSize"
        :num="5"
        :showFirstPage="false"
        :showLastPage="false"
        :total="page.total"
        :pageSizes="[10, 20, 30, 40, 50]"
        v-on:handleChildGetList="handleSizeChange"
      />
    <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes,jumper,slot"
        :total="page.total"
      >
        <span
          >{{ $t("public.total") }}{{ Math.ceil(page.total / page.pageSize)
          }}{{ $t("public.page") }}</span
        >
      </el-pagination> -->
  </div>
</template>

<script>
import Pagination from "@/components/pagination/page.vue";
export default {
  components: { Pagination },
  data() {
    return {
      page: {
        total: 0,
        pageSize: 10,
        orderRule: "",
        orderColume: "",
        currentPage: 1,
      },
      groupIds: "1", //左侧表格数据入参id
      groupList: [], //获取vuex中按部门分配历史记录
      checkNode: [], //勾选左侧表格的数据
      tableList: [], //表格数据
      defaultNode: [], //默认选中的树节点
      searchTable: "", //表格中查询部门
      checkNodeId: [], //勾选左侧表格的数据的id
      turnSearchId: "",
      selectedRows: [], // 已选择的行数据
      rowSelectFlag: false, // 禁止开关
      multipleSelection: [], //表格多选数组
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    tableList(newVal, oldVal) {
      console.log(newVal, "newVal");
      this.showCheck(newVal, this.$store.state.groupRgihtArray);
    },
    "$store.state.groupRgihtArray": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New groupRgihtArray-value: ", newVal);
      console.log("Old groupRgihtArray-value: ", oldVal);
      this.showCheck(this.tableList, newVal);
      if (newVal.length == 0) {
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
      }
    },
    multipleSelection(newVal, oldVal) {
      console.log(newVal, "newVal---multipleSelection");
      this.$emit("groupCheckArray", this.multipleSelection);
    },
    total(newVal, oldVal) {
      this.page.total = newVal;
    },
    pageSize(newVal, oldVal) {
      this.page.pageSize = newVal;
    },
    // 开启深度监听
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.showCheck(this.tableList, this.$store.state.groupRgihtArray);
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //回显的时候默认选中
    showCheck(tableList, arr) {
      // console.log(arr, "回显的时候默认选中", this.$store.state.groupRgihtArray);
      setTimeout(() => {
        this.rowSelectFlag = true;
        // console.log(arr,arr.map((val) => val.id),"葛发财111");
        tableList.forEach((row) => {
          const matchedIndex = arr.findIndex((item) => item.id == row.id);
          // console.log(matchedIndex, "matchedIndex");
          this.$refs["multipleTable"]?.toggleRowSelection(
            row,
            matchedIndex != -1
          );
        });
        this.rowSelectFlag = false;
      }, 0);
    },
    handleSizeChange(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // this.onSearchTable();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      // this.onSearchTable();
    },
    //获取左侧的表格数据
    async getLeftTable(v) {
      console.log(v, "部门");
      this.page.currentPage = 1;
      this.turnSearchId = v;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 9999,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        groupId: v,
        searchName: this.searchTable,
      };
      const res = await this.$axios.post(
        "/httpServe/groups/getDataInfo",
        params,
        true
      );
      console.log(params, "部门左侧的表格数据参数");
      console.log(res, "获取左侧的表格数据");

      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.groupRgihtArray);
      this.page.total = res.data.total;
    },
    //表格查询部门
    async onSearchTable() {
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 9999,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        groupId: this.turnSearchId,
        searchName: this.searchTable,
      };
      const res = await this.$axios.post(
        "/httpServe/groups/getDataInfo",
        params,
        true
      );
      console.log(params, "查询左侧的表格数据参数");
      console.log(res, "查询左侧的表格数据");
      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.groupRgihtArray);
      this.page.total = res.data.total;
    },
    //数组去重
    arrayFilter(val) {
      const map = new Map();
      const uniqueArray = val.filter((item) => {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          return true;
        }
        return false;
      });
      console.log(uniqueArray, "数组去重");
      return uniqueArray;
    },
    // //表格多选
    handleSelectionChange(val) {
      if (this.rowSelectFlag) return;
      this.multipleSelection = this.arrayFilter(val);
      console.log(this.multipleSelection, "this.multipleSelection---301");
      this.$store.commit("group_multiple", this.arrayFilter(val));
    },
    //勾选时触发的事件
    handChange(selection, row) {
      console.log(selection, "selection");
      console.log(row, "row");
      // 判断是选中还是取消 true就是选中，0或者false是取消选中
      let selected = selection.length && selection.indexOf(row) !== -1;
      if (!selected) {
        this.$store.commit("group_del", row.id);
      }
    },
    selectAll(selection) {
      this.selectedRows = selection;
      const allRows = this.tableList;
      const selectedRows = this.selectedRows;
      const unselectedRows = allRows.filter(
        (row) => !selectedRows.includes(row)
      );
      // 在这里可以获取取消选中的行，unselectedRows 就是取消选中的行数组
      console.log(unselectedRows, "获取取消选中的行");
      unselectedRows.forEach((i, v) => {
        this.$store.commit("group_del", i.id);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//分页
.pageBox {
  margin-top: 10px;
}
.searchBtn {
  width: 100px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 20% !important;
}
::v-deep .mt28 {
  padding: 15px 10px !important;
}
</style>
