<template>
  <div class="allocationTable">
    <!-- 查询框 -->
    <el-row :gutter="4" class="el_row_search elRow">
      <el-col :span="6.5">
        <el-input
          v-model="searchTable"
          size="mini"
          type="text"
          :placeholder="
            $t('Delivery.distributionManagement.devGroupPlaceholder')
          "
          @click="onSearchTable"
          @keyup.enter.native="onSearchTable"
          @keyup.native="btKeyUp"
        >
          <i
            @click="onSearchTable"
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer"
          ></i>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" @click="onSearchTable">{{
          $t("Delivery.distributionManagement.query")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="4"  class="elRow"   style="height: 332px; margin-left: 0px; margin-right: 2px; overflow: auto">
      <el-table
        stripe
        row-key="id"
        ref="multipleTable"
        fit
        class="sort_table"
        @select="handChange"
        @selection-change="handleSelectionChange"
        @select-all="selectAllChange"
        tooltip-effect="dark"
        :data="
          tableList.slice(
            (page.currentPage - 1) * page.pageSize,
            page.currentPage * page.pageSize
          )
        "
        style="width:100%; font-size: 12px"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          type="selection"
          align="center"
          :show-overflow-tooltip="true"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.DeviceGroupName')"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="memo"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.Description')"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <Pagination :page="page.currentPage" :num="5" :limit="page.pageSize" :showFirstPage="false" :showLastPage="false" :total="page.total" :pageSizes="[10, 20, 30, 40, 50]" v-on:handleChildGetList="handleSizeChange" />
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.currentPage"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="page.pageSize"
      layout="total, sizes,jumper,slot"
      :total="page.total"
    >
      <span
        >{{ $t("public.total") }}{{ Math.ceil(page.total / page.pageSize)
        }}{{ $t("public.page") }}</span
      >
    </el-pagination> -->
  </div>
</template>
  
  <script>
import Pagination from "@/components/pagination/page.vue";
import selectInput from "@/components/selectInput";
export default {
  components: { Pagination, selectInput },
  props: {
    leftIdArray: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      page: {
        total: 0,
        pageSize: 10,
        orderRule: "",
        currentPage: 1,
        orderColume: "",
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      value: "设备名称",
      options: [
        {
          value: "devicename",
          label: "设备名称",
        },
        // {
        //   value: 'osVersion',
        //   label: '系统版本'
        // },
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
      ],
      groupIds: "1", //左侧表格数据入参id
      seleShift: "devicename", // 选中下拉框转译
      checkNode: [], //勾选左侧表格的数据
      tableList: [], //表格数据
      checkNode: [], //勾选左侧表格的数据
      deviceGroupList: [], //获取vuex中按设备分配历史记录
      searchTable: "", //表格中查询部门
      isCheckShow: false, // 全选弹框
      checkedFlag: false, // 全选框双向绑定
      defaultNode: [], //默认选中的树节点
      checkNodeId: [], //勾选左侧表格的数据的id
      placeholder: "设备名称",
      rowSelectFlag: false, // 禁止开关
      multipleSelection: [], //表格多选数组
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    tableList(newVal, oldVal) {
      console.log(newVal, "newVal", this.$store.state.deviceGropRgihtArray);
      this.showCheck(newVal, this.$store.state.deviceGropRgihtArray);
    },
    "$store.state.deviceGropRgihtStore": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New deviceGropRgihtStore-value: ", newVal);
      console.log("Old deviceGropRgihtStore-value: ", oldVal);
      this.showCheck(this.tableList, newVal);
      if (newVal.length == 0) {
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
      }
    },
    multipleSelection: function () {
      this.$emit("deviceGroupCheckArray", this.multipleSelection);
    },
    total(newVal, oldVal) {
      this.page.total = newVal;
    },
    pageSize(newVal, oldVal) {
      this.page.pageSize = newVal;
    },
    // 开启深度监听
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getLeftTable();
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },

    //回显的时候默认选中
    showCheck(tableList, arr) {
      setTimeout(() => {
        this.rowSelectFlag = true;
        tableList.forEach((row) => {
          const matchedIndex = arr.findIndex((item) => item.id == row.id);
          this.$refs["multipleTable"]?.toggleRowSelection(
            row,
            matchedIndex != -1
          );
        });
        this.rowSelectFlag = false;
      }, 0);
    },
    handleSizeChange(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // this.onSearchTable();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      //调用查询方法
      // this.onSearchTable();
    },
    selectSearchChange(item) {
      this.options.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
      this.placeholder = item;
    },
    //获取左侧的表格数据
    async getLeftTable() {
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 9999,
        name: this.searchTable,
        orderColume: "update_time",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/deviceGroup/getDataInfo",
        params,
        true
      );
      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.deviceGropRgihtStore);
      this.page.total = res.data.total;
    },
    //表格查询部门
    async onSearchTable() {
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 9999,
        name: this.searchTable,
        orderColume: "update_time",
        orderRule: "DESC",
      };

      const res = await this.$axios.post(
        "/httpServe/deviceGroup/getDataInfo",
        params,
        true
      );
      console.log(params, "查询params参数");
      console.log(res, "查询左侧的表格数据");
      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.deviceGropRgihtStore);
      this.page.total = res.data.total;
    },

    //数组去重
    arrayFilter(val) {
      const map = new Map();
      const uniqueArray = val.filter((item) => {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          return true;
        }
        return false;
      });
      // console.log(uniqueArray, "数组去重");
      return uniqueArray;
    },
    //表格多选
    handleSelectionChange(val) {
      if (this.rowSelectFlag) return;
      this.multipleSelection = this.arrayFilter(val);
      this.$store.commit("deviceGroup_multiple", this.arrayFilter(val));
    },
    //表格全选事件
    selectAllChange(selection) {
      if (selection.length == 0) {
        for (let i = 0; i < this.tableList.length; i++) {
          this.$store.commit("deviceGrop_del", this.tableList[i].id);
        }
      }
    },
    //勾选时触发的事件
    handChange(selection, row) {
      console.log(selection, "selection");
      console.log(row, "要判断是选中还是取消row");
      // 判断是选中还是取消 true就是选中，0或者false是取消选中
      // const checkedVal = selection.length && selection.find(x => x.id === row.id)
      let checkedVal = selection.length && selection.indexOf(row) !== -1;
      console.log(checkedVal, "选中还是取消row");
      if (!checkedVal) {
        this.$store.commit("deviceGrop_del", row.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.allocationTable {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 15px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 20% !important;
}
::v-deep .el-select-style .el-input__inner {
  border: 0;
  height: 28px !important;
  line-height: 28px !important;
}
::v-deep .el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
::v-deep .el-select-style {
  height: 26px !important;
  line-height: 26px !important;
  position: absolute;
  left: 2px !important;
  top: 8px !important;
  border: none;
  border-right: 1px solid #c0c4cc !important;
  margin-top: 0px;
  margin-right: 0px;
}
::v-deep .el-col-style .el-button--small {
  height: 28px !important;
  margin-top: 6px !important;
}
::v-deep .mt28 {
  padding: 15px 10px !important;
}
</style>