var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"line-height":"40px","display":"flex","font-size":"13px"}},[_c('el-row',{staticStyle:{"display":"flax","margin-top":"5px"},attrs:{"gutter":2}},[_c('el-col',{attrs:{"span":6}},[_c('el-select',{attrs:{"size":"mini","placeholder":"请输入"},model:{value:(_vm.osType),callback:function ($$v) {_vm.osType=$$v},expression:"osType"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-col',{attrs:{"span":18}},[_c('select-input',{attrs:{"options":_vm.options},on:{"selectSearchChange":_vm.selectSearchChange,"searchList":_vm.onSearchTable}})],1)],1)],1),_c('el-row',{staticStyle:{"margin-left":"0px","margin-right":"2px","height":"330px","overflow":"auto"},attrs:{"gutter":4}},[_c('el-table',{ref:"multipleTable",staticClass:"sort_table",staticStyle:{"width":"100%","font-size":"12px"},attrs:{"stripe":"","row-key":"id","fit":"","tooltip-effect":"dark","data":_vm.tableList.slice(
          (_vm.page.currentPage - 1) * _vm.page.pageSize,
          _vm.page.currentPage * _vm.page.pageSize
        ),"row-style":_vm.iRowStyle,"cell-style":_vm.iCellStyle,"header-row-style":_vm.iHeaderRowStyle,"header-cell-style":{
        background: '#f7f8fa',
        color: '#606266',
        padding: '0px',
      }},on:{"select":_vm.handChange,"selection-change":_vm.handleSelectionChange,"select-all":_vm.selectAll}},[_c('el-table-column',{attrs:{"type":"selection","align":"center","show-overflow-tooltip":true,"reserve-selection":true}}),_c('el-table-column',{attrs:{"prop":"deviceName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.DeviceName'),"width":"120"}}),_c('el-table-column',{attrs:{"prop":"userInfo.loginName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.userName')}}),_c('el-table-column',{attrs:{"prop":"userInfo.userName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.fullName')}}),_c('el-table-column',{attrs:{"prop":"g.groupName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.department')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.groups.groupFullName)?_c('span',[_vm._v(" "+_vm._s(row.groups.groupFullName.substr(_vm.groupFullNameShowByIndex))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"osId","label":_vm.$t('public.OperatingSystem'),"show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.osId == 20)?[_vm._v(" Android ")]:(row.osId == 10)?[_vm._v(" iOS ")]:(row.osId == 30)?[_vm._v(" Windows ")]:(row.osId == 40)?[_vm._v(" Linux ")]:_vm._e()]}}])})],1)],1),_c('Pagination',{attrs:{"page":_vm.page.currentPage,"limit":_vm.page.pageSize,"num":5,"showFirstPage":false,"showLastPage":false,"total":_vm.page.total,"pageSizes":[10, 20, 30, 40, 50]},on:{"handleChildGetList":_vm.handleSizeChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }