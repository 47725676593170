<template>
  <div class="contentList">
    <el-row :gutter="5" style="margin: 10px 0px">
      <el-col :span="9">
        <el-input
          style="margin-left: -4px"
          v-model.trim="input"
          @keyup.enter.native="search"
          :placeholder="$t('Delivery.distributionManagement.notPlaceholder')"
          size="small"
          type="text"
        >
          <i
            style="cursor: pointer"
            class="el-icon-search el-input__icon"
            slot="suffix"
           @click="search"
          >
          </i>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="3">
      <el-table
        @select="selectRows"
        @select-all="selectAll"
        :data="noticeListArray"
        class="sort_table"
        style="width: 99%"
        ref="contentList"
        row-key="id"
        stripe
        tooltip-effect="dark"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="title"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.title')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="groupFullName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.department')"
          width="120"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.publisher')"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.CreationTime')"
        >
        </el-table-column>
      </el-table>
    </el-row>
    <p
      style="
        color: #000;
        line-height: 22px;
        text-align: left;
        margin-left: 10px;
        margin-top: 5px;
      "
    >
      {{ $t("public.total") }}
      <span style="font-weight: bold">{{ total }}</span>
      {{ $t("UserManage.adminPage.Records") }}
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      total: "", //分配数据总数
      input: "", //查询输入框
      noticeListArray: [], //表格数据
      groupFullNameShowByIndex: "",
    };
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      var params = {
        currentPage: 1,
        pageSize: 999,
        searchName: "",
        orderColume: "createTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/notice/getDataInfo",
        params,
        true
      );
      res.data.content.forEach((item) => {
        this.$set(item, "configType", "通知内容");
        this.$set(item, "name", item.title);
      });
      this.noticeListArray = res.data.content;
      this.total = res.data.total;
      if (this.$store.state.dialogVisibleTitle == "5") {
        var listPolicy = [];
        this.$store.state.SelectAssignData.map(function (track) {
          if (track.configType == "5" || track.configType == "通知内容") {
            listPolicy.push(track);
          }
        });
        if (listPolicy.length > 0) {
          // this.rowSelectFlag = true;
          // console.log(arr,arr.map((val) => val.id),"葛发财111");
          res.data.content.forEach((row) => {
            console.log(row, "row");
            const matchedIndex = listPolicy.findIndex(
              (item) => item.id == row.id
            );
            console.log(matchedIndex, "matchedIndex");
            this.$refs.contentList?.toggleRowSelection(row, matchedIndex != -1);
          });
        }
      }
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:35px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    async search() {
      var params = {
        currentPage: 1,
        pageSize: 999,
        searchName: this.input,
        orderColume: "createTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/notice/getDataInfo",
        params,
        true
      );

      this.noticeListArray = res.data.content;
      this.total = res.data.total;
    },
    //全选
    selectAll(selection) {
      this.$emit("selectRows", selection);
    },
    selectRows(selection, row) {
      this.$emit("selectRows", selection);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 27% !important;
}
</style>
