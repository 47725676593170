<template>
  <div style="height: 100%">
    <!-- 查询框 -->
    <div style="line-height: 40px; display: flex; font-size: 13px">
      <el-row :gutter="2" style="display: flax; margin-top: 5px">
        <el-col :span="6">
          <el-select v-model="osType" size="mini" placeholder="请输入">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="18">
          <select-input
            :options="options"
            @selectSearchChange="selectSearchChange"
            @searchList="onSearchTable"
          ></select-input>
        </el-col>
      </el-row>
    </div>
    <el-row
      :gutter="4"
      style="margin-left: 0px; margin-right: 2px; height: 330px; overflow: auto"
    >
      <el-table
        stripe
        row-key="id"
        ref="multipleTable"
        fit
        class="sort_table"
        @select="handChange"
        @selection-change="handleSelectionChange"
        @select-all="selectAll"
        tooltip-effect="dark"
        :data="
          tableList.slice(
            (page.currentPage - 1) * page.pageSize,
            page.currentPage * page.pageSize
          )
        "
        style="width: 100%; font-size: 12px"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          type="selection"
          align="center"
          :show-overflow-tooltip="true"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="deviceName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.DeviceName')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="userInfo.loginName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.userName')"
        >
        </el-table-column>
        <el-table-column
          prop="userInfo.userName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.fullName')"
        >
        </el-table-column>
        <el-table-column
          prop="g.groupName"
          :show-overflow-tooltip="true"
          :label="$t('Delivery.distributionManagement.department')"
        >
          <template v-slot="{ row }">
            <span v-if="row.groups.groupFullName">
              {{ row.groups.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="osId"
          :label="$t('public.OperatingSystem')"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <template v-if="row.osId == 20"> Android </template>
            <template v-else-if="row.osId == 10"> iOS </template>
            <template v-else-if="row.osId == 30"> Windows </template>
            <template v-else-if="row.osId == 40"> Linux </template>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <Pagination
      :page="page.currentPage"
      :limit="page.pageSize"
      :num="5"
      :showFirstPage="false"
      :showLastPage="false"
      :total="page.total"
      :pageSizes="[10, 20, 30, 40, 50]"
      v-on:handleChildGetList="handleSizeChange"
    />
    <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes,jumper,slot"
        :total="page.total"
      >
        <span>{{$t('public.total')}}{{  Math.ceil(page.total / page.pageSize)}}{{$t('public.page') }}</span>
      </el-pagination> -->
  </div>
</template>

<script>
import Pagination from "@/components/pagination/page.vue";
import selectInput from "@/components/selectInput";
export default {
  components: { Pagination, selectInput },
  data() {
    return {
      osType: "",
      page: {
        total: 0,
        pageSize: 10,
        orderRule: "",
        currentPage: 1,
        orderColume: "",
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      value: "设备名称",
      options: [
        {
          value: "devicename",
          label: "设备名称",
        },
        // {
        //   value: 'osVersion',
        //   label: '系统版本'
        // },
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
      ],
      // 下拉框
      options1: [
        {
          value: "",
          label: "操作系统",
        },
        {
          value: "20",
          label: "Android",
        },
        {
          value: "10",
          label: "iOS",
        },
        {
          value: "30",
          label: "Windows",
        },
        {
          value: "40",
          label: "Linux",
        },
      ],
      groupIds: "1", //左侧表格数据入参id
      seleShift: "devicename", // 选中下拉框转译
      checkNode: [], //勾选左侧表格的数据
      tableList: [], //表格数据
      checkNode: [], //勾选左侧表格的数据
      deviceList: [], //获取vuex中按设备分配历史记录
      searchTable: "", //表格中查询部门
      isCheckShow: false, // 全选弹框
      checkedFlag: false, // 全选框双向绑定
      defaultNode: [], //默认选中的树节点
      checkNodeId: [], //勾选左侧表格的数据的id
      placeholder: "设备名称",
      selectedRows: [], // 已选择的行数据
      rowSelectFlag: false, // 禁止toggleRowSelection默认触发handleSelectionChange
      multipleSelection: [], //表格多选数组
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    tableList(newVal, oldVal) {
      console.log(newVal, "newVal");
      this.showCheck(newVal, this.$store.state.deviceRgihtArray);
    },

    "$store.state.deviceRgihtArray": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New deviceRgihtArray-value: ", newVal);
      console.log("Old deviceRgihtArray-value: ", oldVal);
      this.showCheck(this.tableList, newVal);
      if (newVal.length == 0) {
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
      }
    },
    multipleSelection(newVal, oldVal) {
      console.log(newVal, "newVal---multipleSelection");
      this.$emit("deviceCheckArray", this.multipleSelection);
    },
    total(newVal, oldVal) {
      this.page.total = newVal;
    },
    pageSize(newVal, oldVal) {
      this.page.pageSize = newVal;
    },
    // 开启深度监听
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.showCheck(this.tableList, this.$store.state.deviceRgihtArray);
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //回显的时候默认选中
    showCheck(tableList, arr) {
      // console.log(
      //   arr,
      //   "回显的时候默认选中",
      //   this.$store.state.deviceRgihtArray
      // );
      setTimeout(() => {
        this.rowSelectFlag = true;
        // console.log(arr,arr.map((val) => val.id),"葛发财111");
        tableList.forEach((row) => {
          const matchedIndex = arr.findIndex((item) => item.id == row.id);
          // console.log(matchedIndex, "matchedIndex");
          this.$refs["multipleTable"]?.toggleRowSelection(
            row,
            matchedIndex != -1
          );
        });
        this.rowSelectFlag = false;
      }, 0);
    },
    handleSizeChange(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // this.onSearchTable();
    },
    handleCurrentChange(val) {
      console.log(val, "----0000");
      this.page.currentPage = val;
      //调用查询方法
      // this.onSearchTable(this.selectVal);
    },
    selectSearchChange(item) {
      this.options.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
      this.placeholder = item;
    },
    //下拉框
    // changeSele(item) {

    // },
    //获取左侧的表格数据
    async getLeftTable(v) {
      console.log(v, "0000000");
      this.page.currentPage = 1;
      this.turnSearchId = v;
      var params = {
        searchKey: this.seleShift,
        osType: this.osType,
        belong: "",
        groupId: v,
        pageSize: 9999,
        orderRule: "DESC",
        conStatus: "",
        searchKey: this.seleShift,
        signStatus: "",
        currentPage: this.page.currentPage,
        isViolation: "",
        orderColume: "devcon.lastLoginTime",
        deviceStatus: "",
        conStateRate: "",
        searchContent: this.searchTable,
      };
      const res = await this.$axios.post(
        "/httpServe/device/getDataInfo",
        params,
        true
      );
      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.groupRgihtArray);
      this.page.total = res.data.total;
    },
    //表格查询设备左
    async onSearchTable(val) {
      console.log(val, "表格查询设备左");
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 9999,
        orderColume: "devcon.lastLoginTime",
        orderRule: "DESC",
        searchKey: val.deviceName,
        searchContent: val.InputText,
        deviceStatus: "",
        isViolation: "",
        signStatus: "",
        osType: this.osType,
        belong: "",
        conStatus: "",
        conStateRate: "",
        groupId: this.turnSearchId,
      };

      const res = await this.$axios.post(
        "/httpServe/device/getDataInfo",
        params,
        true
      );
      console.log(params, "查询params参数");
      console.log(res, "查询左侧的表格数据");
      this.tableList = res.data.content;
      this.showCheck(this.tableList, this.$store.state.groupRgihtArray);
      this.page.total = res.data.total;
    },
    //数组去重
    arrayFilter(val) {
      const map = new Map();
      const uniqueArray = val.filter((item) => {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          return true;
        }
        return false;
      });
      console.log(uniqueArray, "数组去重");
      return uniqueArray;
    },
    //表格多选
    handleSelectionChange(val) {
      // console.log(
      //   val.map((val) => val.id),
      //   "葛发财--设备",
      //   this.rowSelectFlag
      // );
      if (this.rowSelectFlag) return;
      this.multipleSelection = this.arrayFilter(val);
      this.$store.commit("device_multiple", this.arrayFilter(val));
    },
    //勾选时触发的事件
    handChange(selection, row) {
      console.log(selection, "selection");
      console.log(row, "row");
      // 判断是选中还是取消 true就是选中，0或者false是取消选中
      let selected = selection.length && selection.indexOf(row) !== -1;
      if (!selected) {
        this.$store.commit("device_del", row.id);
      }
    },
    selectAll(selection) {
      this.selectedRows = selection;
      const allRows = this.tableList;
      const selectedRows = this.selectedRows;
      const unselectedRows = allRows.filter(
        (row) => !selectedRows.includes(row)
      );
      // 在这里可以获取取消选中的行，unselectedRows 就是取消选中的行数组
      console.log(unselectedRows, "获取取消选中的行");
      unselectedRows.forEach((i, v) => {
        this.$store.commit("device_del", i.id);
      });
      console.log(selection, "selection111111111111111111111111111111111");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 20% !important;
}

::v-deep .el-col-18 .el-select-style .el-input__inner {
  border: 0;
  height: 28px !important;
  line-height: 28px !important;
}
::v-deep .el-col-18 .el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
::v-deep .el-col-18 .el-input--suffix .el-input__inner {
  height: 24px !important;
  line-height: 24px !important;
}
::v-deep .el-col-18 .el-select-style {
  height: 26px !important;
  line-height: 26px !important;
  position: absolute;
  left: 2px !important;
  top: 8px !important;
  border: none;
  border-right: 1px solid #c0c4cc !important;
  margin-top: 0px;
  margin-right: 0px;
}
::v-deep .el-col-18 .el-col-style .el-button--small {
  height: 28px !important;
  line-height: 2px;
  margin-top: 7px;
}
</style>
