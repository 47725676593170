<template>
  <!-- 按部门分配 -->
  <div class="groupDistri" style="padding: 10px 10px">
    <el-row :gutter="20" ref="box" >
      <el-col :span="15">
        <!-- 左侧部分 -->
        <div class="userLeftBox"  v-dragControllerDiv  ref="userLeftBox">
          <!-- 树状图部分 -->
          <div class="leftTree" style="max-width: 60%">
            <!-- 查询框 -->
            <div class="wrap_tree">
              <el-input
                v-model.trim="searchGroup"
                size="mini"
                type="text"
                :placeholder="
                  $t('Delivery.distributionManagement.groupPlaceholder')
                "
                style="width: 100%"
                @input="inputChange"
                @keyup.native="btKeyUp"
              >
                <i
                  @click="inputChange"
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  style="cursor: pointer"
                ></i>
              </el-input>
              <!-- //模糊搜索弹框 -->
              <div v-show="showFlag">
                <ul
                  style="
                    overflow: hidden auto;
                    padding: 1px 0px;
                    height: 400px;
                    margin-bottom: 30px;
                    box-shadow: 0px 0px 10px rgb(232, 232, 232);
                  "
                >
                  <li
                    :title="item.groupName"
                    class="souBox"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding-left: 14px;
                      border: 1px solid #c6c6c6;
                      color: gray;
                      margin-top: -1px;
                      font-size: 12px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: block;
                    "
                    v-for="(item, index) in searchResult"
                    :key="index"
                    @click="clickList(item)"
                  >
                    {{ item.groupName }}
                  </li>
                </ul>
              </div>
              <div class="treeBox">
                <Tree @tree="trees" />
              </div>
            </div>

            <!-- 拖拽图标 -->
            <div class="dragPoint" style="right: -11px">
              <i>
                <img src="../../../../images/icon_drag.png" alt="" />
              </i>
            </div>
          </div>
          <!-- 表格部分 -->
          <div class="leftTable">
            <!-- 表格 :treeNodeIdStr="treeNodeId":leftIdArray="userChecked"-->
            <left-table
              @userCheckArray="childLeft"
              ref="leftTable"
            ></left-table>
          </div>
        </div>
      </el-col>
      <!-- 右侧已选择部分 @search="search" @userCancelArray="userCancelArray1"-->
      <el-col :span="9" class="rightBox">
        <right-table
          :rightArray="rightData"
          ref="rightTable"
          @allDelFlg="allDelFlg1"
        ></right-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import rightTable from "../disManagement/tableComponents/userRightTable";
import leftTable from "../disManagement/tableComponents/userLeftTable";
import Tree from "@/components/treeSulation/childGroupsTree.vue"; //树形组织
export default {
  components: { rightTable, leftTable, Tree },
  data() {
    return {
      checked: false, // tree是否有节点选中
      treeData: [],
      treeNodeId: "", //点击tree节点id

      treeShow: true, //树状图显示----默认显示
      showFlag: false, //搜索数据显示---默认隐藏
      rightData: [], //右侧表格数据展示
      treeClick: "", //右侧默认展示数据
      searchGroup: "", //树状图中查询部门的查询内容
      rightSearch: [], //右侧搜索数据
      rightSearch: "", //搜索历史记录
      defaultProps: {
        label: "name",
        children: "children",
      },
      searchResult: [], //模糊搜索结果
      nodeClickFlag: false, //节点是否点击标志
      currentNodeKey: sessionStorage.getItem("manageGroupId"), //tree默认高亮
      defaultExpandedKeys: [sessionStorage.getItem("manageGroupId")], //树状图默认展开的节点数据
    };
  },
  mounted() {
    // this.dragControllerDiv();
    this.$refs.leftTable.getLeftTable(sessionStorage.getItem("manageGroupId"));
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },

    // 查询输入框
    async inputChange(v) {
      if (v.length > 0) {
        this.searchGroup = v;
        var params = {
          searchName: this.searchGroup,
        };
        const res = await this.$axios.post(
          "/httpServe/groups/selectListByName",
          params,
          true
        );

        this.showFlag = true;
        this.treeShow = false;
        this.searchResult = res.data.content;
      } else if (v == "") {
        this.showFlag = false;
        this.treeShow = true;
      }
    },
    //点击搜索结果
    clickList(v) {
      this.$refs.leftTable.getLeftTable(v.id);
    },

    // //树状图外部盒子拖拽
    // dragControllerDiv: function () {
    //   var dragPoint = document.getElementsByClassName("dragPoint");
    //   var leftTree = document.getElementsByClassName("leftTree");
    //   var leftTable = document.getElementsByClassName("leftTable");
    //   var userLeftBox = document.getElementsByClassName("userLeftBox");
    //   for (let i = 0; i < dragPoint.length; i++) {
    //     // 鼠标按下事件
    //     dragPoint[i].onmousedown = function (e) {
    //       //颜色改变提醒
    //       // dragPoint[i].style.background = "#818181";
    //       var startX = e.clientX; //鼠标按下时鼠标指针的横向坐标
    //       dragPoint[i].leftTree = dragPoint[i].offsetLeft;
    //       // 鼠标拖动事件
    //       document.onmousemove = function (e) {
    //         // // console.log(e, 'eeeee')
    //         var endX = e.clientX; //鼠标拖动时鼠标指针的横向坐标
    //         var moveLen = dragPoint[i].leftTree + (endX - startX); // （endx-startx）=移动的距离。dragPoint[i].left+移动的距离=左边区域最后的宽度
    //         var maxT = userLeftBox.clientWidth - dragPoint[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
    //         if (moveLen < 232) moveLen = 232; // 左边区域的最小宽度为232px
    //         if (moveLen > maxT - 150) moveLen = maxT; //右边区域最小宽度为150px
    //         dragPoint[i].style.leftTree = moveLen; // 设置左侧区域的宽度
    //         for (let j = 0; j < leftTree.length; j++) {
    //           leftTree[j].style.width = moveLen + "px";
    //           // leftTable[j].style.width =
    //           userLeftBox.clientWidth - moveLen - 10 + "px";
    //         }
    //       };
    //       // 鼠标松开事件
    //       document.onmouseup = function (evt) {
    //         //颜色恢复
    //         // dragPoint[i].style.background = "#d6d6d6";
    //         document.onmousemove = null;
    //         document.onmouseup = null;
    //         dragPoint[i].releaseCapture && dragPoint[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
    //       };
    //       dragPoint[i].setCapture && dragPoint[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
    //       return false;
    //     };
    //   }
    // },
    //树状图节点选中状态发生变化时的回调
    handleCheckChange(data, checked, indeterminate) {
      this.checked = checked;
    },
    // 树状图组件抛出的点击事件
    trees(val) {
      this.defaultExpandedKeys = [];
      this.defaultExpandedKeys.push(val);
      this.$refs.leftTable?.getLeftTable(val);
    },

    //左侧表格要王右侧表格传递的数据
    childLeft(res) {
      this.rightSearch = res;
    },
    //右侧全部删除时左侧表格清除勾选数据
    allDelFlg1() {
      this.$refs.leftTable.$refs.multipleTable.clearSelection();
      this.$refs.rightTable.rightDataArray.forEach(() => {
        this.$refs.rightTable.rightDataArray.pop();
      });
      this.$store.commit("user_all", "all");
    },
  },
};
</script>

<style lang="scss" scoped>
.userLeftBox,
.rightBox {
  height: 463px;
  max-height: 650px;
  border: 2px solid #1d89e3;
  padding: 0px 10px 0;
  box-sizing: border-box;
  font-size: 12px;
}
//左侧
.userLeftBox {
  //第一个div内左侧树状图部分
  float: left;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  .leftTree {
    float: left;
    height: 440px;
    max-height: 448px;
    border-radius: 3px;
    width: 230px;
    position: relative;
    border: 1px solid #cdd1dd;
    padding: 10px 10px 0px 10px;

    .dragPoint {
      width: 21px;
      height: 21px;
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 47%;
      margin-top: -15px;
      cursor: col-resize;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
    //树状图
    .treeBox {
      margin-top: 10px;
      height: 388px;
      overflow-y: auto;
    }
  }
  //第一个div内右侧表格部分
  .leftTable {
    display: inline-block;
    font-size: 12px;
    width: calc(100% - 230px);
    position: relative;
    padding-left: 10px;
  }
}
//右侧
.rightBox {
  .el-tree-node__content {
    padding-left: 0px !important;
  }
}

.souBox:hover {
  background-color: #e8e8e9;
  cursor: pointer !important;
}
</style>
<style lang="scss">
//树状图
.wrap_tree {
  background: #fff !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.treeBox {
  margin-top: 10px;
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #ffe6b0;
    color: black;
    border: 1px #ffb951 solid;
    // height: 18px;
    opacity: 0.8;
  }
  .el-tree-node__content {
    &:hover {
      background-color: #ffe6b0;
    }
  }
  // el-tree-node__label
  .el-tree-node__content .custom-tree-node {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
