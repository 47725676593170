<template>
  <!-- 按用户组分配 -->
  <div class="userDistri">
    <el-row :gutter="20" class="box" ref="box" >
      <el-col :span="15">
        <!-- 左侧部分 -->
        <div class="leftBox" ref="leftBox">
          <!-- 表格部分 -->
          <div class="leftTable">
            <!-- 表格 :leftIdArray="userChecked"-->
            <left-table  @UserGroupCheckArray="childLeft" ref="leftTable"></left-table>
          </div>
        </div>
      </el-col>
      <!-- 右侧已选择部分 @userCancelArray="userCancelArray1" @search="search"-->
      <el-col :span="9" class="rightBox">
        <right-table :rightArray="rightData" ref="rightTable"  @allDelFlg="allDelFlg1"></right-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import rightTable from '../disManagement/tableComponents/UserGroupRightTable'
import leftTable from '../disManagement/tableComponents/UserGroupLeftTable'
import Tree from '@/components/treeSulation/childGroupsTree.vue' //树形组织
export default {
  components: { rightTable, leftTable, Tree },
  data() {
    return {
      checked: false, // tree是否有节点选中
      treeData: [],

      treeShow: true, //树状图显示----默认显示
      showFlag: false, //搜索数据显示---默认隐藏
      rightData: [], //右侧表格数据展示
      treeClick: '', //右侧默认展示数据
      searchGroup: '', //树状图中查询部门的查询内容
      rightSearch: [], //右侧搜索数据
      rightSearch: '', //搜索历史记录
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      searchResult: [], //模糊搜索结果
      nodeClickFlag: false, //节点是否点击标志
      currentNodeKey: sessionStorage.getItem('manageGroupId'), //tree默认高亮
      defaultExpandedKeys: [sessionStorage.getItem('manageGroupId')] //树状图默认展开的节点数据
    }
  },
  mounted() {
    // this.$refs.leftTable.getLeftTable(sessionStorage.getItem('manageGroupId'))
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ''
        )
        .replace(/\s/g, '')
    },


    //左侧表格要王右侧表格传递的数据
    childLeft(res) {
      // this.rightData = res
      this.rightSearch = res
    },
    //右侧全部删除时左侧表格清除勾选数据
    allDelFlg1() {
      this.$refs.leftTable.$refs.multipleTable.clearSelection()
      this.$refs.rightTable.rightDataArray.forEach(() => {
        this.$refs.rightTable.rightDataArray.pop()
      })
      this.$store.commit('userGrop_all', 'all')
    },
  }
}
</script>

<style lang="scss" scoped>
.userDistri{
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  font-family: Microsoft YaHei;
}
.leftBox,
.rightBox {
  height: 463px;
  max-height: 650px;
  border: 2px solid #1d89e3;
  box-sizing: border-box;
  font-size: 12px;
}
//左侧
.leftBox {
  //第一个div内左侧树状图部分
  float: left;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  .leftTree {
    float: left;
    height: 440px;
    max-height: 448px;
    border-radius: 3px;
    width: 230px;
    position: relative;
    border: 1px solid #cdd1dd;
    padding: 10px 10px 0px 15px;

    .dragPoint {
      width: 21px;
      height: 21px;
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 47%;
      margin-top: -15px;
      cursor: col-resize;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
    //树状图
    .treeBox {
      margin-top: 10px;
      height: 388px;
      overflow-y: auto;
    }
  }
  //第一个div内右侧表格部分
  .leftTable {
    display: inline-block;
    font-size: 12px;
    width: 100%;
    position: relative;
  }
}
//右侧
.rightBox {
  .el-tree-node__content {
    padding-left: 0px !important;
  }
}

.souBox:hover {
  background-color: #e8e8e9;
  cursor: pointer !important;
}
</style>
<style lang="scss">
//树状图
.wrap_tree {
  background: #fff !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.treeBox {
  margin-top: 10px;
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #ffe6b0;
    color: black;
    border: 1px #ffb951 solid;
    // height: 18px;
    opacity: 0.8;
  }
  .el-tree-node__content {
    &:hover {
      background-color: #ffe6b0;
    }
  }
  // el-tree-node__label
  .el-tree-node__content .custom-tree-node {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
