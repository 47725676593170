<template>
  <div class="allocationTable">
    <!-- 查询框 -->
    <el-row :gutter="4" class=" elRow">
      <el-col :span="6.5">
        <el-input
          v-model="chooseTable"
          size="mini"
          type="text"
          :placeholder="
            $t('Delivery.distributionManagement.userGroupPlaceholder')
          "
          @click="onChoose"
          @keyup.enter.native="onChoose"
          @keyup.native="btKeyUp"
        >
          <i
            @click="onChoose"
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer"
          ></i>
        </el-input>
      </el-col>
      <el-col :span="24" style="line-height: 28px; margin-top: 2px">
        <div v-if="chooseTable == ''" style="font-size: 12px; float: left">
          {{ $t("Delivery.distributionManagement.Selected")
          }}{{ rightDataArray.length }}
        </div>
        <div v-else-if="chooseTable != ''" style="font-size: 12px; float: left">
          {{ $t("Delivery.distributionManagement.Selected")
          }}{{ rightSearch.length }}
        </div>
        <el-button
          type="primary"
          size="mini"
          style="float: right; margin-right: 2px"
          @click="allDel"
          >{{ $t("Delivery.distributionManagement.deleteAll") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="4" style="height: 342px; margin-left: 0px; margin-right: 2px;  overflow: auto">
      <el-table
        stripe
        ref="multipleTables"
        fit
        class="sort_table"
        tooltip-effect="dark"
        :data="chooseTable == '' ? rightDataArray : rightSearch"
        style="width: 100%; font-size: 12px"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          prop="name"
          :label="$t('Delivery.distributionManagement.UserGroupName')"
          width="180"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="memo"
          :label="$t('Delivery.distributionManagement.Description')"
          width="300"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <img
              @click="deletes(row)"
              :title="$t('public.Delete')"
              src="../../../../../assets/icon_del.png"
              alt=""
              style="
                width: 15px;
                height: 15px;
                margin-top: 8px;
                margin-left: 5px;
                cursor: pointer;
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
  
  <script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: { pagination },
  data() {
    return {
      page: {
        total: 0,
        orderRule: "",
        pageSize: 10,
        orderColume: "",
        currentPage: 1,
      },
      defaultObj: {}, //默认的第一条数据
      chooseTable: "", //查询已选中查询
      rightArray: [], //表格数据
      rightSearch: [], //搜索历史记录
      rightDataArray: [], //表格数据
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    "$store.state.userGropRgihtStore": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New userGropRgihtStore-value: ", newVal);
      console.log("Old userGropRgihtStore-value: ", oldVal);
      this.getUserGropRgihtList(newVal);
    },
    "$store.state.addUserGropList": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New addUserGropList-value: ", newVal);
      console.log("Old addUserGropList-value: ", oldVal);
      this.$store.commit("userGropRgiht", newVal);
    },
    rightDataArray(newVal, oldVal) {
      this.rightSearch = newVal;
      this.$store.commit("userGropRgihtList", newVal);
    },
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getUserGropRgihtList(this.$store.state.userGropRgihtStore);
  },

  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding: 0px";
    },

    getUserGropRgihtList(val) {
      this.rightDataArray = val;
      this.rightSearch = val;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用查询方法
      this.onChoose();
    },
    //已选中查询
    onChoose() {
      // this.$emit("search", this.chooseTable);
      console.log(this.chooseTable, "this.chooseTable");
      if (this.chooseTable !== "") {
        if (item.name.includes(data)) {
          return item.name.includes(data);
        }
        if (item.name.includes(data)) {
          return item.name.includes(data);
        }
      } else if (this.chooseTable == "") {
        this.rightSearch = [];
      }
    },
    // 单选删除
    deletes(row, index) {
      this.$store.commit("userGrop_del", row.id);
      this.$store.commit("userGropRgihtDelete", row.id);
    },

    //全部删除
    allDel() {
      this.$emit("allDelFlg", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.allocationTable {
  width: 100%;
  height: 100%;
  padding: 10px 0px 0px 0px !important; //页面最外层上右左10
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 27% !important;
}
</style>
  