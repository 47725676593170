<template>
  <div>
    <!-- 查询框 -->
    <el-row style="height: 70px"  class="elRow">
      <el-col :span="24">
        <el-input
          v-model="chooseTable"
          size="mini"
          type="text"
          style="width: 200px; margin-top: 10px"
          :placeholder="
            $t('Delivery.distributionManagement.userRigPlaceholder')
          "
          @click="onChoose"
          @keyup.enter.native="onChoose"
          @keyup.native="btKeyUp"
        >
          <i
            @click="onChoose"
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer"
          ></i>
        </el-input>
      </el-col>
      <el-col :span="24" style="line-height: 28px; margin-top: 2px">
        <div v-if="chooseTable == ''" style="font-size: 12px; float: left">
          {{ $t("Delivery.distributionManagement.Selected")
          }}{{ rightDataArray.length }}
        </div>
        <div v-else-if="chooseTable != ''" style="font-size: 12px; float: left">
          {{ $t("Delivery.distributionManagement.Selected")
          }}{{ rightSearch.length }}
        </div>
        <el-button
          type="primary"
          size="mini"
          style="float: right;"
          @click="allDel"
          >{{ $t("Delivery.distributionManagement.deleteAll") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row
      :gutter="4"
      class="mt15"
      style="
        position: relative;
        margin-top: 2px;
        height: 342px;
        overflow: auto;
      "
    >
      <el-table
        stripe
        ref="multipleTables"
        fit
        class="sort_table"
        tooltip-effect="dark"
        :data="chooseTable == '' ? rightDataArray : rightSearch"
        style="width: 100%; font-size: 12px"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          prop="loginName"
          :label="$t('Delivery.distributionManagement.userName')"
          width="120"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('Delivery.distributionManagement.fullName')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="groupFullName"
          :label="$t('Delivery.distributionManagement.department')"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <img
              @click="deletes(row)"
              :title="$t('public.Delete')"
              src="../../../../../assets/icon_del.png"
              alt=""
              style="
                width: 15px;
                height: 15px;
                margin-top: 8px;
                margin-left: 5px;
                cursor: pointer;
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: { pagination },
  data() {
    return {
      page: {
        total: 0,
        orderRule: "",
        pageSize: 10,
        orderColume: "",
        currentPage: 1,
      },
      defaultObj: {}, //默认的第一条数据
      chooseTable: "", //查询已选中查询
      rightArray: [], //表格数据
      rightSearch: [], //搜索历史记录
      rightDataArray: [], //表格数据
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    "$store.state.userRgihtStore": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New userRgihtStore-value: ", newVal);
      console.log("Old userRgihtStore-value: ", oldVal);
      this.getUserRightList(newVal);
    },
    "$store.state.addUserList": function (newVal, oldVal) {
      // 在这里可以对vuex中state的值进行监听和处理
      console.log("New addUserList-value: ", newVal);
      console.log("Old addUserList-value: ", oldVal);
      this.$store.commit("userRgiht", newVal);
    },
    rightDataArray(newVal, oldVal) {
      this.rightSearch = newVal;
      this.$store.commit("userRgihtList", newVal);
    },
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getUserRightList(this.$store.state.userRgihtStore);
  },

  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding: 0px";
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用查询方法
      this.onChoose();
    },
    getUserRightList(val) {
      this.rightDataArray = val;
      this.rightSearch = val;
    },
    //已选中查询
    onChoose() {
      console.log(this.chooseTable, "this.chooseTable", this.rightArray);
      if (this.chooseTable !== "") {
        if (item.loginName.includes(data)) {
          return item.loginName.includes(data);
        }
        if (item.userName.includes(data)) {
          return item.userName.includes(data);
        }
      }else  if (this.chooseTable == "") {
        this.rightSearch=[]
      }
    },
    // 单选删除
    deletes(row) {
      this.$store.commit("user_del", row.id);
      this.$store.commit("userRgihtDelete", row.id);
    },

    //全部删除
    allDel() {
      this.$emit("allDelFlg", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
// 暂无数据
::v-deep .el-table__empty-block {
  width: 27% !important;
}
</style>
