var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentList"},[_c('el-row',{staticStyle:{"margin":"10px 0px"},attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":9}},[_c('el-input',{staticStyle:{"margin-left":"-4px"},attrs:{"placeholder":_vm.$t('Delivery.distributionManagement.devicePlaceholder'),"size":"small","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"input"}},[_c('i',{staticClass:"el-icon-search el-input__icon",staticStyle:{"cursor":"pointer"},attrs:{"slot":"suffix"},on:{"click":_vm.search},slot:"suffix"})])],1)],1),_c('el-row',{staticStyle:{"margin":"10px 0px"},attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":24}},[_c('el-breadcrumb',{staticStyle:{"margin":"10px","color":"#333333","font-size":"12px"},attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{staticStyle:{"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.goHome.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("Delivery.distributionManagement.docManagement")))]),_vm._l((_vm.menuName),function(item){return _c('el-breadcrumb-item',{key:item.id,staticStyle:{"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.changeBread(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)],1)],1),_c('el-row',{attrs:{"gutter":3}},[_c('el-table',{ref:"contentList",staticClass:"sort_table",staticStyle:{"width":"99%"},attrs:{"row-key":"id","stripe":"","data":_vm.documentListArray,"tooltip-effect":"dark","row-style":_vm.iRowStyle,"cell-style":_vm.iCellStyle,"header-row-style":_vm.iHeaderRowStyle,"header-cell-style":{
        background: '#f7f8fa',
        color: '#606266',
        padding: '0px',
      }},on:{"select":_vm.selectRows,"select-all":_vm.selectAll}},[_c('el-table-column',{attrs:{"type":"selection","reserve-selection":true,"align":"center","width":"60"}}),_c('el-table-column',{attrs:{"prop":"name","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.name'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.docType == 'folder')?[_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/folder.png"),"alt":""}}),_c('span',{staticStyle:{"color":"#d38a08","cursor":"pointer"},on:{"click":function($event){return _vm.newDoc(row)}}},[_vm._v(_vm._s(row.name))])]:[[(row.docType == 'txt')?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/txt.png"),"alt":""}}):_vm._e(),(row.docType == 'pdf')?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/pdf.png"),"alt":""}}):_vm._e(),(
                  row.docType == 'jpeg' ||
                  row.docType == 'jpg' ||
                  row.docType == 'pen' ||
                  row.docType == 'png'
                )?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/jpg.png"),"alt":""}}):_vm._e(),(row.docType == 'ppt' || row.docType == 'pptx')?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/ppt.png"),"alt":""}}):_vm._e(),(row.docType == 'docx' || row.docType == 'doc')?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/docx.png"),"alt":""}}):_vm._e(),(row.docType == 'xls' || row.docType == 'xlsx')?_c('img',{staticClass:"nameImg",attrs:{"src":require("@/images/file/xls.png"),"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(row.name))])]]]}}])}),_c('el-table-column',{attrs:{"prop":"groupFullName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.department'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.groupFullName)?_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(row.groupFullName.substr(_vm.groupFullNameShowByIndex))+" ")]):_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(row.groupFullName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"userName","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.creator'),"width":"140"}}),_c('el-table-column',{attrs:{"prop":"lastUpdateTime","show-overflow-tooltip":true,"label":_vm.$t('Delivery.distributionManagement.ModificationTime')}})],1)],1),_c('p',{staticStyle:{"color":"#000","line-height":"22px","text-align":"left","margin-left":"10px","margin-top":"5px"}},[_vm._v(" "+_vm._s(_vm.$t("public.total"))+" "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.total))]),_vm._v(" "+_vm._s(_vm.$t("UserManage.adminPage.Records"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }